import {create} from 'zustand';
import {persist} from 'zustand/middleware';

type State = {
    isLoading: boolean
    userName: string
    accessToken: string
    assistantId: string
    infoUser: {
        name: string;
        last_name: string;
        email: string;
        phone: string;
        address: string;
        city: string;
        country: string;
        postal_code: string;
    }
    language: string;
    firstToken: string;
    organizationId: string
}

type Actions = {
    setIsLoading: () => void,
    setUserName: (userName: string | undefined) => void
    setInfoUser: (infoUser: State['infoUser']) => void;
    setAccessToken: (accessToken: State['accessToken']) => void,
    setPageId: (pageId: string) => void
    setLanguage: (language: string) => void
    setFirstToken: (firstToken: string) => void
    setOrganizationId: (organizationId: string) => void
    resetAuthState: () => void;
}

export const authStore = create<State & Actions>()(
    persist(
        (set) => ({
            isLoading: false,
            setIsLoading: () => set((state) => ({isLoading: !state.isLoading})),

            userName: '',
            setUserName: (userName) => set(() => ({userName: userName || ''})),

            infoUser: {
                name: '',
                last_name: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                country: '',
                postal_code: '',
            },
            setInfoUser: (infoUser) => set(() => ({ infoUser })),

            accessToken: '',
            setAccessToken: (token) => set(() => ({accessToken: token})),

            assistantId: '',
            setPageId: (pageId) => set(() => ({assistantId: pageId})),

            language: '',
            setLanguage: (lang) => set(() => ({language: lang})),

            firstToken: '',
            setFirstToken: (firstToken) => set(() => ({firstToken: firstToken})),

            organizationId: '',
            setOrganizationId: (organizationId) => set(() => ({organizationId: organizationId})),

            resetAuthState: () =>
                set(() => ({
                    organizationId: '',
                    userName: '',
                    accessToken: '',
                    firstToken: '',
                    infoUser: {
                        name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        address: '',
                        city: '',
                        country: '',
                        postal_code: '',
                    },
                })),
        }),
        {
            name: 'auth-store',
            partialize: (state) => ({
                language: state.language,
                userName: state.userName,
                firstToken: state.firstToken,
                organizationId: state.organizationId,
                infoUser: state.infoUser,
            }),
        }
    )
);

