import axios from 'axios';

interface UpdateOrganizationData {
    name: string;
    description?: string;
    numberFiscal?: string; 
    direction?: string;
    population?: string; 
    postalCode?: string; 
    province?: string; 
    country?: string; 
    image?: File;
}

export const updateOrganization = async (
    token: string,
    data: UpdateOrganizationData,
    organizationId: string,
) => {
    const response = await axios.patch(
        `${import.meta.env.VITE_API_ENDPOINT}/api/organizations/${organizationId}`,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        }
    );

    return response.data;
};
