import axios from 'axios';

interface CreateOrganizationData {
    name: string;
    description?: string;
    numberFiscal?: string; 
    direction?: string;
    population?: string; 
    postalCode?: string; 
    province?: string; 
    country?: string; 
    image?: File;
}

export const createOrganization = async (
    token: string,
    data: CreateOrganizationData
) => {
    const response = await axios.post(
        `${import.meta.env.VITE_API_ENDPOINT}/api/organizations`,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        }
    );
      
    return response.data;
};
