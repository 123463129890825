import React, { useEffect, useState } from "react";
import { Box, Flex, SimpleGrid, Spinner } from "@chakra-ui/react";
import ProductCard from "../../ui/Components/CardsProducts";
import Title from '../../ui/Components/Title';
import { fetchPlans } from "../../api/Calls/products";
import { useLogto } from "@logto/react";
import { useRedirectStripe }from '../../hooks/useRedirectStripe'
import { moreProducts } from "./mocks";
import { useTranslation } from "react-i18next";
import { linkProductActive, mergeProductsWithPlans } from "./utils";
import { PlanData } from "./interfaces";
import useToastError from '../../hooks/useToastError/index';
import { useGetTokenOrganization } from "../../hooks/useGetAccessTokenOrganization";

const ProductList: React.FC = () => {
    const { t } = useTranslation();
    const [planData, setPlanData] = useState<PlanData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { signOut } = useLogto()
    const redirectStripe = useRedirectStripe();
    const showToastError = useToastError();
    const { fetchUpdatedToken } = useGetTokenOrganization();
    
    useEffect(() => {
        const fetchDataPlan = async (token: string) => {
            try {
                const data = await fetchPlans(token,signOut, showToastError);
                const dataMerge = mergeProductsWithPlans(moreProducts, data);
                setPlanData(dataMerge)
            } catch {   
                setError(t("errorAPI"));
            } finally {
                setIsLoading(false)
            }
        };
        fetchUpdatedToken().then(token => {
            return fetchDataPlan(token ? token : "");
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchUpdatedToken, t]);

    
    if (error) return <p>{error}</p>;


    return (
        <Box maxW="1350px" mx="auto"> 
            <Title title={t("welcomeProducts")}/>
           
            {!isLoading && planData
                ?   <Box mb="90px" >
                    <SimpleGrid columns={{ base: 1, sm: 3, md: 3}} spacing="25px" minChildWidth="330px">
                        {planData.map((data, index) => (
                            <ProductCard
                                key={index}
                                title={data.name}
                                plan={data.plan}
                                onClick={() => redirectStripe(data.name)}
                                textButton={data?.comingSoon ?  t("comingSoon") : t("upgrade")}
                                description={t(data.description)}
                                linkProductActive={linkProductActive}
                            />
                        ))}
                    </SimpleGrid>
                </Box>
                : <Flex minHeight="500px" minW="200px" alignItems="center" justifyContent="center">
                    <Spinner color="teal.500" size="lg" />
                </Flex>

            }
        </Box>

    
    );
};

export default ProductList;
