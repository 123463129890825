import React from 'react';

interface elevateSvgProps extends React.SVGAttributes<SVGElement> {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const elevateSvg: React.FC<elevateSvgProps> = ({
    width = 202,
    height = 42,
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 202 42" fill="none">
        <path d="M16.6065 22.7427C18.0999 22.7427 19.3106 21.532 19.3106 20.0386C19.3106 18.5451 18.0999 17.3345 16.6065 17.3345C15.113 17.3345 13.9023 18.5451 13.9023 20.0386C13.9023 21.532 15.113 22.7427 16.6065 22.7427Z" fill="#FF6F9A"/>
        <path d="M24.0127 22.7427C25.5062 22.7427 26.7168 21.532 26.7168 20.0386C26.7168 18.5451 25.5062 17.3345 24.0127 17.3345C22.5193 17.3345 21.3086 18.5451 21.3086 20.0386C21.3086 21.532 22.5193 22.7427 24.0127 22.7427Z" fill="#FF6F9A"/>
        <path d="M31.4151 22.7427C32.9085 22.7427 34.1192 21.532 34.1192 20.0386C34.1192 18.5451 32.9085 17.3345 31.4151 17.3345C29.9216 17.3345 28.7109 18.5451 28.7109 20.0386C28.7109 21.532 29.9216 22.7427 31.4151 22.7427Z" fill="#FF6F9A"/>
        <path d="M33.6022 30.0254C32.4494 29.1635 30.8065 29.2443 29.8153 30.2893C28.878 31.2751 27.8007 32.11 26.5833 32.8049C24.6333 33.9146 22.4948 34.4694 20.1624 34.4694C18.2663 34.4694 16.494 34.087 14.8349 33.322C13.1758 32.5571 11.7268 31.5175 10.4933 30.2085C9.25433 28.8996 8.27395 27.3698 7.54136 25.6191C6.81416 23.8684 6.44787 21.9938 6.44787 19.99C6.44787 17.9861 6.81416 16.1116 7.54136 14.3609C8.26857 12.6156 9.25433 11.0858 10.4933 9.77145C11.7322 8.46248 13.1812 7.42285 14.8349 6.65794C16.494 5.89304 18.2663 5.51058 20.1624 5.51058C22.4948 5.51058 24.6333 6.06541 26.5833 7.17507C27.8007 7.86995 28.878 8.70489 29.8153 9.69065C30.8119 10.7303 32.4494 10.8165 33.6022 9.9546C35.0458 8.87726 35.1697 6.76029 33.8715 5.51058C32.514 4.20162 30.9788 3.09196 29.2551 2.17622C26.5079 0.727203 23.4752 0 20.1624 0C17.4313 0 14.8619 0.527895 12.4594 1.58369C10.0569 2.63948 7.96152 4.06695 6.17853 5.87149C4.39554 7.67603 2.98422 9.78761 1.94459 12.2062C0.904963 14.6302 0.387842 17.2212 0.387842 19.99C0.387842 22.7588 0.904963 25.3551 1.94459 27.7738C2.40246 28.8403 2.94113 29.8369 3.54444 30.7849L0 42L12.1362 38.2455C12.2439 38.294 12.3463 38.3532 12.4594 38.4017C14.8619 39.4575 17.4313 39.9854 20.1624 39.9854C23.4752 39.9854 26.5079 39.2582 29.2605 37.7984C30.9788 36.888 32.5194 35.7784 33.8769 34.464C35.1751 33.2143 35.0512 31.0973 33.6075 30.02L33.6022 30.0254Z" fill="#FF6F9A"/>
        <path d="M47.4807 38.3423C46.8397 38.3423 46.2957 38.1214 45.854 37.6797C45.4123 37.238 45.1914 36.694 45.1914 36.0529V8.58085C45.1914 7.93983 45.4123 7.39577 45.854 6.95407C46.2957 6.51236 46.8397 6.2915 47.4807 6.2915H65.7955C66.4365 6.2915 66.9806 6.51236 67.4223 6.95407C67.864 7.39577 68.0848 7.93983 68.0848 8.58085C68.0848 9.22186 67.864 9.76592 67.4223 10.2076C66.9806 10.6493 66.4365 10.8702 65.7955 10.8702H49.7701V24.6062H62.7736C63.3822 24.6062 63.9155 24.8271 64.3788 25.2688C64.8367 25.7105 65.0629 26.2546 65.0629 26.8956C65.0629 27.5366 64.842 28.0645 64.4003 28.4739C63.9586 28.8833 63.4146 29.1257 62.7736 29.1849H49.7701V33.7636H65.7955C66.4365 33.7636 66.9806 33.9845 67.4223 34.4262C67.864 34.8679 68.0848 35.4119 68.0848 36.0529C68.0848 36.694 67.864 37.238 67.4223 37.6797C66.9806 38.1214 66.4365 38.3423 65.7955 38.3423H47.4807Z" fill="#333333"/>
        <path d="M73.1211 6.29178C73.1211 5.65077 73.3419 5.10671 73.7837 4.665C74.2254 4.2233 74.7694 4.00244 75.4104 4.00244C76.0515 4.00244 76.5955 4.2233 77.0372 4.665C77.4789 5.10671 77.6998 5.65077 77.6998 6.29178V36.0532C77.6998 36.6942 77.4789 37.2383 77.0372 37.68C76.5955 38.1217 76.0515 38.3426 75.4104 38.3426C74.7694 38.3426 74.2254 38.1217 73.7837 37.68C73.3419 37.2383 73.1211 36.6942 73.1211 36.0532V6.29178Z" fill="#333333"/>
        <path d="M86.8138 29.1853C87.2716 30.5266 88.0904 31.6255 89.2647 32.4819C90.439 33.3384 91.7749 33.764 93.2724 33.764C94.2205 33.764 95.1093 33.5808 95.9496 33.2145C96.7899 32.8482 97.5117 32.358 98.1258 31.7493C98.5514 31.3238 99.09 31.1083 99.731 31.1083C100.372 31.1083 100.922 31.3238 101.379 31.7493C101.805 32.2072 102.02 32.7513 102.02 33.3761C102.02 34.001 101.805 34.545 101.379 35.0029C100.313 36.0425 99.09 36.8559 97.7164 37.4538C96.3428 38.0464 94.8615 38.348 93.2724 38.348C91.6833 38.348 90.1966 38.0518 88.8068 37.4538C87.4171 36.8559 86.2051 36.0425 85.1654 35.0029C84.1258 33.9633 83.3124 32.7513 82.7145 31.3615C82.1166 29.9717 81.8203 28.485 81.8203 26.8959C81.8203 25.3069 82.1166 23.8201 82.7145 22.4304C83.3124 21.0406 84.1258 19.8286 85.1654 18.789C86.2051 17.7494 87.4171 16.936 88.8068 16.338C90.1966 15.7401 91.6833 15.4438 93.2724 15.4438C94.8615 15.4438 96.3482 15.7401 97.738 16.338C99.1277 16.9306 100.34 17.7494 101.379 18.789C102.419 19.8286 103.232 21.0406 103.83 22.4304C104.423 23.8201 104.725 25.3069 104.725 26.8959C104.725 27.5046 104.504 28.0379 104.062 28.5012C103.62 28.959 103.076 29.1853 102.435 29.1853H86.8245H86.8138ZM99.7257 24.6066C99.2678 23.2653 98.449 22.1664 97.2747 21.31C96.1004 20.4589 94.7645 20.0279 93.267 20.0279C91.7695 20.0279 90.4336 20.4535 89.2593 21.31C88.085 22.1664 87.2501 23.2653 86.7653 24.6066H99.7257Z" fill="#333333"/>
        <path d="M108.196 18.7458C107.921 18.1963 107.883 17.6253 108.083 17.0274C108.282 16.4349 108.67 15.9824 109.252 15.6753C109.801 15.4006 110.372 15.3629 110.97 15.5622C111.563 15.7615 112.015 16.1494 112.322 16.7311L119.422 30.9251L126.516 16.6827C126.823 16.1332 127.27 15.7615 127.868 15.5622C128.466 15.3629 129.053 15.4006 129.629 15.6753C130.179 15.9824 130.551 16.4295 130.75 17.0274C130.949 17.6253 130.912 18.2125 130.637 18.7888L121.479 37.0605C121.329 37.3029 121.162 37.5184 120.978 37.7015C120.828 37.8523 120.618 37.9978 120.359 38.1378C120.1 38.2725 119.788 38.3425 119.422 38.3425C119.055 38.3425 118.732 38.2725 118.457 38.1378C118.183 37.9978 117.967 37.8577 117.816 37.7015C117.633 37.5184 117.483 37.3029 117.359 37.0605L108.201 18.7458H108.196Z" fill="#333333"/>
        <path d="M152.426 36.0099C151.451 36.7425 150.379 37.3081 149.221 37.7067C148.062 38.1053 146.84 38.2992 145.558 38.2992C143.968 38.2992 142.482 38.0029 141.092 37.405C139.702 36.8071 138.49 35.9937 137.451 34.9541C136.411 33.9144 135.598 32.7024 135 31.3127C134.402 29.9229 134.105 28.4362 134.105 26.8471C134.105 25.258 134.402 23.7713 135 22.3816C135.592 20.9918 136.411 19.7798 137.451 18.7402C138.49 17.7005 139.702 16.8871 141.092 16.2892C142.482 15.6967 143.968 15.395 145.558 15.395C147.147 15.395 148.633 15.6913 150.023 16.2892C151.413 16.8871 152.625 17.7005 153.665 18.7402C154.704 19.7798 155.518 20.9918 156.115 22.3816C156.713 23.7713 157.01 25.258 157.01 26.8471V36.0476C157.01 36.6886 156.789 37.2326 156.347 37.6744C155.905 38.1161 155.361 38.3369 154.72 38.3369C154.079 38.3369 153.535 38.1161 153.094 37.6744C152.652 37.2326 152.431 36.6886 152.431 36.0476V36.0045L152.426 36.0099ZM145.558 33.7205C146.506 33.7205 147.394 33.5374 148.235 33.1711C149.075 32.8048 149.797 32.3146 150.411 31.7059C151.025 31.0972 151.51 30.37 151.876 29.5297C152.242 28.6894 152.426 27.7952 152.426 26.8525C152.426 25.9098 152.242 25.0156 151.876 24.1753C151.51 23.335 151.02 22.6132 150.411 21.9991C149.802 21.3904 149.075 20.9002 148.235 20.5339C147.394 20.1676 146.5 19.9845 145.558 19.9845C144.615 19.9845 143.721 20.1676 142.88 20.5339C142.04 20.9002 141.318 21.3904 140.704 21.9991C140.095 22.6078 139.605 23.335 139.239 24.1753C138.873 25.0156 138.69 25.9098 138.69 26.8525C138.69 27.7952 138.873 28.6947 139.239 29.5297C139.605 30.37 140.095 31.0918 140.704 31.7059C141.313 32.3146 142.04 32.8048 142.88 33.1711C143.721 33.5374 144.61 33.7205 145.558 33.7205Z" fill="#333333"/>
        <path d="M162.041 8.58085C162.041 7.93983 162.261 7.39577 162.703 6.95407C163.145 6.51236 163.689 6.2915 164.33 6.2915C164.971 6.2915 165.515 6.51236 165.957 6.95407C166.398 7.39577 166.619 7.93983 166.619 8.58085V15.4489H170.282C170.923 15.4489 171.467 15.6697 171.909 16.1114C172.351 16.5531 172.572 17.0972 172.572 17.7382C172.572 18.3792 172.351 18.9233 171.909 19.365C171.467 19.8067 170.923 20.0276 170.282 20.0276H166.619V31.4743C166.619 32.083 166.84 32.6162 167.282 33.0795C167.723 33.5374 168.268 33.7636 168.909 33.7636C169.55 33.7636 170.051 33.5374 170.508 33.0795C170.966 32.6216 171.516 32.39 172.157 32.39C172.798 32.39 173.331 32.6162 173.757 33.0795C174.214 33.505 174.446 34.0383 174.446 34.6793C174.446 35.3204 174.214 35.8698 173.757 36.3277C173.148 36.9364 172.421 37.4266 171.58 37.7929C170.74 38.1591 169.846 38.3423 168.903 38.3423C167.961 38.3423 167.066 38.1591 166.226 37.7929C165.386 37.4266 164.664 36.9364 164.05 36.3277C163.441 35.719 162.951 34.9918 162.585 34.1514C162.218 33.3111 162.035 32.4223 162.035 31.4743V8.58085H162.041Z" fill="#333333"/>
        <path d="M183.333 29.1853C183.791 30.5266 184.61 31.6255 185.784 32.4819C186.959 33.3384 188.294 33.764 189.792 33.764C190.74 33.764 191.629 33.5808 192.469 33.2145C193.309 32.8482 194.031 32.358 194.645 31.7493C195.071 31.3238 195.61 31.1083 196.245 31.1083C196.881 31.1083 197.436 31.3238 197.894 31.7493C198.319 32.2072 198.535 32.7513 198.535 33.3761C198.535 34.001 198.319 34.545 197.894 35.0029C196.827 36.0425 195.604 36.8559 194.231 37.4538C192.857 38.0464 191.376 38.348 189.792 38.348C188.208 38.348 186.716 38.0518 185.326 37.4538C183.937 36.8559 182.725 36.0425 181.685 35.0029C180.645 33.9633 179.832 32.7513 179.234 31.3615C178.642 29.9717 178.34 28.485 178.34 26.8959C178.34 25.3069 178.636 23.8201 179.234 22.4304C179.832 21.0406 180.645 19.8286 181.685 18.789C182.725 17.7494 183.937 16.936 185.326 16.338C186.716 15.7401 188.203 15.4438 189.792 15.4438C191.381 15.4438 192.868 15.7401 194.258 16.338C195.647 16.9306 196.859 17.7494 197.899 18.789C198.939 19.8286 199.752 21.0406 200.35 22.4304C200.948 23.8201 201.244 25.3069 201.244 26.8959C201.244 27.5046 201.023 28.0379 200.581 28.5012C200.14 28.959 199.596 29.1853 198.955 29.1853H183.344H183.333ZM196.24 24.6066C195.782 23.2653 194.963 22.1664 193.789 21.31C192.615 20.4589 191.279 20.0279 189.781 20.0279C188.284 20.0279 186.948 20.4535 185.773 21.31C184.599 22.1664 183.764 23.2653 183.279 24.6066H196.234H196.24Z" fill="#333333"/>
    </svg>
);

export default elevateSvg;
