import React from "react";
import ElevateIcon from "../../../../icons/imageProducts/elevate";
import PlayIcon from "../../../../icons/imageProducts/play";
import CollectIcon from "../../../../icons/imageProducts/collect";
import AdIcon from "../../../../icons/imageProducts/ad";
import AssistIcon from "../../../../icons/imageProducts/assist";
import TalentIcon from "../../../../icons/imageProducts/talent";

interface IconSelectorProps {
  name: string;
}

const IconSelector: React.FC<IconSelectorProps> = ({ name }) => {
    switch (name.toLowerCase()) {
    case "elevate":
        return <ElevateIcon />;
    case "play":
        return <PlayIcon />;
    case "collect":
        return <CollectIcon />;
    case "ad":
        return <AdIcon />;
    case "assist":
        return <AssistIcon />;
    case "talent":
        return <TalentIcon />;
    default:
        return <ElevateIcon />;
    }
};


export default IconSelector;
