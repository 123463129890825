import React from 'react';

interface talentSvgProps extends React.SVGAttributes<SVGElement> {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const talentSvg: React.FC<talentSvgProps> = ({
    width = 169,
    height = 42,
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 169 42" fill="none">
        <path d="M50.6964 12.7286H43.8284C43.1874 12.7286 42.6433 12.5077 42.2016 12.066C41.7599 11.6243 41.5391 11.0803 41.5391 10.4392C41.5391 9.79823 41.7599 9.25417 42.2016 8.81247C42.6433 8.37076 43.1874 8.1499 43.8284 8.1499H62.1431C62.7842 8.1499 63.3282 8.37076 63.7699 8.81247C64.2116 9.25417 64.4325 9.79823 64.4325 10.4392C64.4325 11.0803 64.2116 11.6243 63.7699 12.066C63.3282 12.5077 62.7842 12.7286 62.1431 12.7286H55.2751V37.9113C55.2751 38.5524 55.0543 39.0964 54.6125 39.5381C54.1708 39.9798 53.6268 40.2007 52.9858 40.2007C52.3448 40.2007 51.8007 39.9798 51.359 39.5381C50.9173 39.0964 50.6964 38.5524 50.6964 37.9113V12.7286Z" fill="#333333"/>
        <path d="M82.7498 37.8629C81.7748 38.5955 80.7029 39.1611 79.5447 39.5597C78.3866 39.9583 77.1638 40.1522 75.8818 40.1522C74.2927 40.1522 72.806 39.856 71.4162 39.258C70.0265 38.6601 68.8145 37.8467 67.7748 36.8071C66.7352 35.7675 65.9218 34.5555 65.3239 33.1657C64.7313 31.7759 64.4297 30.2892 64.4297 28.7001C64.4297 27.1111 64.726 25.6243 65.3239 24.2346C65.9218 22.8448 66.7352 21.6328 67.7748 20.5932C68.8145 19.5535 70.0265 18.7402 71.4162 18.1422C72.806 17.5443 74.2927 17.248 75.8818 17.248C77.4709 17.248 78.9576 17.5443 80.3473 18.1422C81.7371 18.7402 82.9491 19.5535 83.9887 20.5932C85.0284 21.6328 85.8418 22.8448 86.4397 24.2346C87.0376 25.6243 87.3339 27.1111 87.3339 28.7001V37.9006C87.3339 38.5416 87.113 39.0857 86.6713 39.5274C86.2296 39.9691 85.6856 40.1899 85.0445 40.1899C84.4035 40.1899 83.8595 39.9691 83.4178 39.5274C82.9761 39.0857 82.7552 38.5416 82.7552 37.9006V37.8575L82.7498 37.8629ZM75.8818 35.5736C76.8298 35.5736 77.7186 35.3904 78.559 35.0241C79.3993 34.6578 80.1211 34.1676 80.7352 33.5589C81.3439 32.9502 81.8341 32.223 82.2004 31.3827C82.5667 30.5424 82.7498 29.6482 82.7498 28.7055C82.7498 27.7629 82.5667 26.8687 82.2004 26.0283C81.8341 25.188 81.3439 24.4662 80.7352 23.8521C80.1265 23.2434 79.3993 22.7532 78.559 22.3869C77.7186 22.0207 76.8245 21.8375 75.8818 21.8375C74.9391 21.8375 74.0395 22.0207 73.2046 22.3869C72.3643 22.7532 71.6371 23.2434 71.0284 23.8521C70.4197 24.4608 69.9295 25.188 69.5632 26.0283C69.1969 26.8687 69.0138 27.7629 69.0138 28.7055C69.0138 29.6482 69.1969 30.5478 69.5632 31.3827C69.9295 32.223 70.4197 32.9449 71.0284 33.5589C71.6371 34.1676 72.3643 34.6578 73.2046 35.0241C74.0449 35.3904 74.9337 35.5736 75.8818 35.5736Z" fill="#333333"/>
        <path d="M92.3633 8.15018C92.3633 7.50917 92.5841 6.96511 93.0258 6.5234C93.4675 6.08169 94.0116 5.86084 94.6526 5.86084C95.2936 5.86084 95.8377 6.08169 96.2794 6.5234C96.7211 6.96511 96.942 7.50917 96.942 8.15018V37.9116C96.942 38.5526 96.7211 39.0967 96.2794 39.5384C95.8377 39.9801 95.2936 40.201 94.6526 40.201C94.0116 40.201 93.4675 39.9801 93.0258 39.5384C92.5841 39.0967 92.3633 38.5526 92.3633 37.9116V8.15018Z" fill="#333333"/>
        <path d="M106.056 31.0432C106.514 32.3845 107.333 33.4834 108.507 34.3398C109.681 35.1963 111.017 35.6219 112.515 35.6219C113.463 35.6219 114.351 35.4387 115.192 35.0724C116.032 34.7061 116.754 34.216 117.368 33.6073C117.794 33.1817 118.332 32.9662 118.973 32.9662C119.614 32.9662 120.164 33.1817 120.622 33.6073C121.047 34.0651 121.263 34.6092 121.263 35.234C121.263 35.8589 121.047 36.403 120.622 36.8608C119.555 37.9004 118.332 38.7138 116.959 39.3118C115.585 39.9097 114.104 40.206 112.515 40.206C110.926 40.206 109.439 39.9097 108.049 39.3118C106.659 38.7192 105.447 37.9004 104.408 36.8608C103.368 35.8212 102.555 34.6092 101.957 33.2194C101.359 31.8297 101.062 30.3429 101.062 28.7539C101.062 27.1648 101.359 25.6781 101.957 24.2883C102.549 22.8985 103.368 21.6865 104.408 20.6469C105.447 19.6073 106.659 18.7939 108.049 18.1959C109.439 17.598 110.926 17.3018 112.515 17.3018C114.104 17.3018 115.59 17.598 116.98 18.1959C118.37 18.7885 119.582 19.6073 120.622 20.6469C121.661 21.6865 122.475 22.8985 123.073 24.2883C123.67 25.6781 123.967 27.1648 123.967 28.7539C123.967 29.3626 123.746 29.8958 123.304 30.3537C122.862 30.8116 122.318 31.0432 121.677 31.0432H106.067H106.056ZM118.968 26.4645C118.51 25.1232 117.691 24.0243 116.517 23.1679C115.343 22.3168 114.007 21.8858 112.509 21.8858C111.012 21.8858 109.676 22.3114 108.502 23.1679C107.327 24.0243 106.492 25.1232 106.007 26.4645H118.968Z" fill="#333333"/>
        <path d="M128.078 19.597C128.078 18.9559 128.299 18.4119 128.741 17.9702C129.182 17.5285 129.726 17.3076 130.367 17.3076C131.008 17.3076 131.553 17.5285 131.994 17.9702C132.436 18.4119 132.657 18.9559 132.657 19.597C133.632 18.8967 134.704 18.3365 135.862 17.9271C137.02 17.5177 138.243 17.3076 139.525 17.3076C141.114 17.3076 142.601 17.6039 143.99 18.2018C145.38 18.7943 146.592 19.6131 147.632 20.6527C148.671 21.6924 149.485 22.9044 150.083 24.2941C150.675 25.6839 150.977 27.1706 150.977 28.7597V37.9171C150.977 38.5581 150.756 39.1022 150.314 39.5439C149.873 39.9856 149.329 40.2064 148.688 40.2064C148.047 40.2064 147.503 39.9856 147.061 39.5439C146.619 39.1022 146.398 38.5581 146.398 37.9171V28.7597C146.398 27.8117 146.215 26.9229 145.849 26.0825C145.483 25.2422 144.992 24.5204 144.384 23.9063C143.775 23.2976 143.048 22.8074 142.207 22.4411C141.367 22.0748 140.473 21.8917 139.53 21.8917C138.588 21.8917 137.693 22.0748 136.853 22.4411C136.013 22.8074 135.291 23.2976 134.677 23.9063C134.063 24.515 133.578 25.2422 133.212 26.0825C132.845 26.9229 132.662 27.817 132.662 28.7597V37.9171C132.662 38.5581 132.441 39.1022 132 39.5439C131.558 39.9856 131.014 40.2064 130.373 40.2064C129.732 40.2064 129.188 39.9856 128.746 39.5439C128.304 39.1022 128.084 38.5581 128.084 37.9171V19.6023L128.078 19.597Z" fill="#333333"/>
        <path d="M156.008 10.4392C156.008 9.79823 156.229 9.25417 156.67 8.81247C157.112 8.37076 157.656 8.1499 158.297 8.1499C158.938 8.1499 159.482 8.37076 159.924 8.81247C160.366 9.25417 160.586 9.79823 160.586 10.4392V17.3073H164.249C164.89 17.3073 165.435 17.5281 165.876 17.9698C166.318 18.4115 166.539 18.9556 166.539 19.5966C166.539 20.2376 166.318 20.7817 165.876 21.2234C165.435 21.6651 164.89 21.886 164.249 21.886H160.586V33.3327C160.586 33.9414 160.807 34.4746 161.249 34.9325C161.691 35.3904 162.235 35.622 162.876 35.622C163.517 35.622 164.018 35.3958 164.481 34.9325C164.944 34.4693 165.488 34.2484 166.129 34.2484C166.77 34.2484 167.304 34.4746 167.729 34.9325C168.187 35.3581 168.419 35.8967 168.419 36.5377C168.419 37.1788 168.192 37.7282 167.729 38.1861C167.121 38.7948 166.393 39.285 165.553 39.6512C164.713 40.0175 163.819 40.2007 162.876 40.2007C161.933 40.2007 161.039 40.0175 160.199 39.6512C159.358 39.285 158.637 38.7948 158.022 38.1861C157.414 37.5774 156.924 36.8502 156.557 36.0098C156.191 35.1695 156.008 34.2807 156.008 33.3327V10.4392Z" fill="#333333"/>
        <path d="M16.6065 22.7427C18.0999 22.7427 19.3106 21.532 19.3106 20.0386C19.3106 18.5451 18.0999 17.3345 16.6065 17.3345C15.113 17.3345 13.9023 18.5451 13.9023 20.0386C13.9023 21.532 15.113 22.7427 16.6065 22.7427Z" fill="#965BA0"/>
        <path d="M24.0127 22.7427C25.5062 22.7427 26.7168 21.532 26.7168 20.0386C26.7168 18.5451 25.5062 17.3345 24.0127 17.3345C22.5193 17.3345 21.3086 18.5451 21.3086 20.0386C21.3086 21.532 22.5193 22.7427 24.0127 22.7427Z" fill="#965BA0"/>
        <path d="M31.4151 22.7427C32.9085 22.7427 34.1192 21.532 34.1192 20.0386C34.1192 18.5451 32.9085 17.3345 31.4151 17.3345C29.9216 17.3345 28.7109 18.5451 28.7109 20.0386C28.7109 21.532 29.9216 22.7427 31.4151 22.7427Z" fill="#965BA0"/>
        <path d="M33.6022 30.0254C32.4494 29.1635 30.8065 29.2443 29.8153 30.2893C28.878 31.2751 27.8007 32.11 26.5833 32.8049C24.6333 33.9146 22.4948 34.4694 20.1624 34.4694C18.2663 34.4694 16.494 34.087 14.8349 33.322C13.1758 32.5571 11.7268 31.5175 10.4933 30.2085C9.25433 28.8996 8.27395 27.3698 7.54136 25.6191C6.81416 23.8684 6.44786 21.9938 6.44786 19.99C6.44786 17.9861 6.81416 16.1116 7.54136 14.3609C8.26856 12.6156 9.25433 11.0858 10.4933 9.77145C11.7322 8.46248 13.1812 7.42285 14.8349 6.65794C16.494 5.89304 18.2663 5.51058 20.1624 5.51058C22.4948 5.51058 24.6333 6.06541 26.5833 7.17507C27.8007 7.86995 28.878 8.70489 29.8153 9.69065C30.8118 10.7303 32.4494 10.8165 33.6022 9.9546C35.0458 8.87726 35.1697 6.76029 33.8715 5.51058C32.514 4.20162 30.9788 3.09196 29.2551 2.17622C26.5079 0.727203 23.4752 0 20.1624 0C17.4313 0 14.8619 0.527895 12.4594 1.58369C10.0569 2.63948 7.96152 4.06695 6.17853 5.87149C4.39554 7.67603 2.98422 9.78761 1.94459 12.2062C0.904963 14.6302 0.387841 17.2212 0.387841 19.99C0.387841 22.7588 0.904963 25.3551 1.94459 27.7738C2.40246 28.8403 2.94113 29.8369 3.54444 30.7849L0 42L12.1362 38.2455C12.2439 38.294 12.3463 38.3532 12.4594 38.4017C14.8619 39.4575 17.4313 39.9854 20.1624 39.9854C23.4752 39.9854 26.5079 39.2582 29.2605 37.7984C30.9788 36.888 32.5194 35.7784 33.8769 34.464C35.1751 33.2143 35.0512 31.0973 33.6075 30.02L33.6022 30.0254Z" fill="#965BA0"/>
    </svg>
);

export default talentSvg;
