import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            welcome: "Welcome",
            profile: "Profile",
            settings: "Settings",
            activeSubscriptions: "Active subscriptions",
            planAndBilling: "Plans & Billing",
            welcomeProducts: "Available products",
            exploreMoreProducts: "Explore more products",
            comingSoon: "Coming soon",
            subscribe: "Subscribe",
            upgrade: "Upgrade",
            success: "Success",
            successUser: "User changed successfully",
            successPassword: "Password changed successfully.",
            allChangesWereSavedSuccessfully: "All changes were saved successfully.",
            partialSuccess: "Partial success",
            yourProfileUpdatedButIssueUpdatingPassword: "Your profile was updated, but there was an issue updating your password.",
            yourPasswordUpdatedButIssueUpdatingProfile:"Your password was updated, but there was an issue updating your profile.",
            noChangesSavedDueError: "No changes were saved due to an error.",
            thereWasIssueWithRequest: "There was an issue with your request.",
            personalInformation: "Personal information",
            youCanChangeAccountSettingsHere: "You can change your account here.",
            errorAuthenticating: "There was an error with your authentication.",
            manageSubscription: "Manage subscription",
            of: "of",
            credits: "credits",
            youNotHaveActiveSubscription: "You don’t have any active subscription",
            name: "Name",
            email: "Email",
            password: "Password",
            oldPassword: "Actual password",
            newPassword: "New password",
            language: "Language",
            spanish: "Spanish",
            english: "English",
            save: "Save",
            cancel: "Cancel",
            catalog: "Catalog",
            assistDescription: "Get rid of high dependence on people for customer service with our conversational assistant. Unlock your company's potential by offering quality service 24/7.",
            playDescription: "It is a virtual innovation laboratory designed to explore and experiment with the latest artificial intelligence technologies.",
            talentDescription: "An innovative training tool powered by artificial intelligence, designed to transform learning into an exciting and fun experience",
            elevateDescription: "With CanAI.Elevate you can have your own knowledge base to consult any information among all the documentation of your company, in a secure and reliable way.",
            inactiveSubscriptions: "Inactive subscriptions",
            logOut: "Log out",
            errorAPI: "There was an error in the application.",
            users: "Users",
            permissions: "Permissions",
            dashboard: "Dashboard",
            uploadYourProfilePhoto: "Upload your profile photo",
            collectDescription: "CanAI.Collect is a tool that optimizes debt management with a conversational assistant that reminds your customers of their financial commitments in a friendly and persuasive way.",
            adDescription: "Plan a marketing campaign for your assistant to post content on your social networks autonomously, update information about your business and your presence on social networks will never be the same again.",

        }
    },
    es: {
        translation: {
            welcome: "Bienvenido",
            profile: "Perfil",
            settings: "Configuración",
            activeSubscriptions: "Suscripción activa",
            planAndBilling: "Planes y facturación",
            welcomeProducts: "Productos disponibles",
            exploreMoreProducts: "Explorar más productos",
            comingSoon: "Próximamente",
            subscribe: "Suscríbete",
            upgrade: "Actualizar",
            success: "Guardado",
            successUser: "Usuario cambiado correctamente.",
            successPassword: "Contraseña cambiada correctamente.",
            allChangesWereSavedSuccessfully: "Todos los cambios fueron guardados correctamente.",
            partialSuccess: "Guardado parcialmente",
            yourProfileUpdatedButIssueUpdatingPassword: "Tu perfil se actualizó, pero hubo un problema con la contraseña.",
            yourPasswordUpdatedButIssueUpdatingProfile:"Tu contraseña fue actualizada, pero hubo un problema con tu perfil.",
            noChangesSavedDueError: "No se guardaron los cambios debido a un error.",
            thereWasIssueWithRequest: "Hubo un error en la petición.",
            personalInformation: "Información personal",
            youCanChangeAccountSettingsHere: "Puedes hacer cambios en tu cuenta aquí.",
            errorAuthenticating: "Hubo un error al autentificar..",
            manageSubscription : "Administrar suscripción",
            of: "de",
            credits: "creditos",
            youNotHaveActiveSubscription: "No tienes ninguna suscripción activa",
            name: "Nombre",
            email: "Email",
            password: "Contraseña",
            oldPassword: "Contraseña actual",
            newPassword: "Contraseña nueva",
            language: "Idioma",
            spanish: "Español",
            english: "Inglés",
            save: "Guardar",
            cancel: "Cancelar",
            catalog: "Catalogo",
            assistDescription: "Deshazte de la alta dependencia de las personas para la atención al cliente con nuestro asistente conversacional. Libera el potencial de tu empresa ofreciendo un servicio de calidad 24/7.",
            playDescription: "Es un laboratorio virtual de innovación diseñado para explorar y experimentar con las últimas tecnologías de inteligencia artificial",
            talentDescription: "Una herramienta de formación innovadora impulsada por inteligencia artificial, diseñada para transformar el aprendizaje en una experiencia emocionante y divertida",
            elevateDescription: "Con CanAI.Elevate puedes tener tu propia base de conocimiento para consultar cualquier información entre toda la documentación de tu empresa, de una forma segura y confiable.",
            inactiveSubscriptions: "Suscripciones inactivas",
            logOut: "Cerrar sesión",
            errorAPI: "Hubo un error en la aplicación.",
            users: "Usuarios",
            permissions: "Permisos",
            dashboard: "Tablero",
            uploadYourProfilePhoto: "Sube tu foto de perfil",
            collectDescription: "CanAI.Collect es una herramienta que optimiza la gestión de deuda con un asistente conversacional que recuerda a tus clientes sus compromisos financieros de manera amigable y persuasiva. ",
            adDescription: "Planifica una campaña de marketing para que tu asistente publique contenido en tus redes sociales de manera autónoma, actualiza la información sobre tu negocio y tu presencia en las redes sociales nunca volver a ser la misma.",
        }
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: "es", // Initial language
    fallbackLng: "en", // Language in case there is no translation
    interpolation: {
        escapeValue: false, // react-i18next takes care of security
    }
});



export default i18n;
