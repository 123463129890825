import React, {useEffect, useState} from "react";
import {useForm, SubmitHandler} from "react-hook-form";
import {Box} from "@chakra-ui/react";
import ProfileForm from "../../ui/Components/Form";
import {authStore} from "../../store/authStore";
import {ProfileFormData} from "./interfaces";
import {updatePassword, updateUser} from "../../api/Calls/form";
import {useLogto} from "@logto/react";
import {useTranslation} from "react-i18next";
import {useGetTokenOrganization} from "../../hooks/useGetAccessTokenOrganization";
import {useCustomToast} from '@canaia/ui-kit';
import '@canaia/ui-kit/dist/style.css';
import axios from "axios";

const ProfileFormContainer: React.FC = () => {
    const {signOut} = useLogto();
    const {t} = useTranslation();
    const showToast = useCustomToast();
    const infoUser = authStore((state) => state.infoUser.email);
    const lastName = authStore((state) => state.infoUser.last_name);
    const userName = authStore((state) => state.userName);
    const language = authStore((state) => state.language);
    const {fetchUpdatedToken} = useGetTokenOrganization();

    const {control, handleSubmit, formState: {errors, isSubmitting}, reset, watch} = useForm<ProfileFormData>({
        defaultValues: {
            email: infoUser || "",
            name: userName || "",
            last_name: lastName || "",
            language: language || '',
            address: authStore.getState().infoUser?.address || "",
            city: authStore.getState().infoUser?.city || "",
            country: authStore.getState().infoUser?.country || "",
            phone: authStore.getState().infoUser?.phone || "",
            postal_code: authStore.getState().infoUser?.postal_code || "",
            profilePhoto: undefined,
        }
    });

    const {setUserName, setLanguage} = authStore.getState();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [initialData, setInitialData] = useState<ProfileFormData>({
        address: "",
        city: "",
        country: "",
        phone: "",
        postal_code: "",
        profilePhoto: undefined,
        email: infoUser || "",
        name: userName || "",
        last_name: lastName || "",
        old_password: "",
        new_password: "",
        repeat_password: "",
        language: language || ""
    });

    useEffect(() => {
        reset({
            email: infoUser,
            name: userName,
            language: language,
            address: authStore.getState().infoUser?.address || "",
            city: authStore.getState().infoUser?.city || "",
            country: authStore.getState().infoUser?.country || "",
            phone: authStore.getState().infoUser?.phone || "",
            postal_code: authStore.getState().infoUser?.postal_code || "",
        });
    }, [infoUser, userName, language, reset]);

    const handleUpdateUser: SubmitHandler<ProfileFormData> = async (data) => {
        try {
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("Failed to obtain access token.");

            await updateUser(
                token,
                {
                    name: data.name,
                    last_name: data.last_name,
                    phone: data.phone,
                    address: data.address,
                    city: data.city,
                    postal_code: data.postal_code,
                    country: data.country,
                    language: data.language
                },
                signOut,
            );

            setUserName(data.name);
            setLanguage(data.language);
            authStore.getState().setInfoUser({
                email: data.email,
                name: data.name,
                last_name: data.last_name,
                address: data.address,
                city: data.city,
                country: data.country,
                phone: data.phone,
                postal_code: data.postal_code,
            });

            setInitialData({
                ...initialData,
                name: data.name,
                language: data.language,
            });

            showToast({
                type: "success",
                title: t("¡Éxito!"),
                description: t("Tu perfil se ha actualizado correctamente."),
                duration: 5000,
                position: "bottom-center",
            });

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    showToast({
                        type: "warning",
                        title: "Error de Validación",
                        description: "Hubo un problema con los datos ingresados. Por favor, verifica e inténtalo de nuevo.",
                        duration: 5000,
                        position: "bottom-center",
                    });
                } else if (error.response?.status === 401 || error.response?.status === 403) {
                    showToast({
                        type: "error",
                        title: "Error de Autenticación",
                        description: "Tu sesión ha expirado. Por favor, inicia sesión nuevamente.",
                        duration: 5000,
                        position: "bottom-center",
                    });
                } else {
                    showToast({
                        type: "error",
                        title: "Error",
                        description: "Hubo un problema al procesar tu solicitud. Inténtalo más tarde.",
                        duration: 5000,
                        position: "bottom-center",
                    });
                }
            } else {
                console.error("Error desconocido:", error);
            }
        }
    };

    const handleUpdatePassword: SubmitHandler<ProfileFormData> = async (data) => {
        try {
            const {old_password, new_password, repeat_password} = data;

            if (new_password !== repeat_password) {
                showToast({
                    type: "warning",
                    title: t("Cuidado"),
                    description: t("Las contraseñas no coinciden"),
                    duration: 5000,
                    position: "bottom-center",
                });
                return;
            }
            const token = await fetchUpdatedToken();
            if (!token) throw new Error("Failed to obtain access token.");

            await updatePassword(
                token,
                {old_password, new_password},
                signOut,
            );
            showToast({
                type: "success",
                title: t("¡Éxito!"),
                description: t("La contraseña fue actualizada correctamente"),
                duration: 5000,
                position: "bottom-center",
            });
            setIsModalOpen(false);
            reset({
                old_password: "",
                new_password: "",
                repeat_password: "",
            });
        } catch (error) {
            console.error("Error updating password:", error);

            if (axios.isAxiosError(error)) {
                const { response } = error;
                if (response?.status === 400) {

                    showToast({
                        type: "warning",
                        title: t("Error de Validación"),
                        description: t("Hubo un problema con los datos ingresados. Verifica tu contraseña actual."),
                        duration: 5000,
                        position: "bottom-center",
                    });
                } else if (response?.status === 401 || response?.status === 403) {

                    showToast({
                        type: "error",
                        title: t("Sesión Expirada"),
                        description: t("Tu sesión ha expirado. Por favor, inicia sesión nuevamente."),
                        duration: 5000,
                        position: "bottom-center",
                    });
                    await signOut();
                } else {

                    showToast({
                        type: "error",
                        title: t("Error del Servidor"),
                        description: t("Hubo un error al procesar tu solicitud. Intenta de nuevo más tarde."),
                        duration: 5000,
                        position: "bottom-center",
                    });
                }
            } else {

                showToast({
                    type: "error",
                    title: t("Error"),
                    description: t("Hubo un error inesperado. Intenta de nuevo más tarde."),
                    duration: 5000,
                    position: "bottom-center",
                });
            }
        }
    };


    const handleCancel = () => {
        if (initialData) {
            reset(initialData);
        }
    };

    return (
        <Box
            maxW="900px"
            mx={"auto"}
        >
            <ProfileForm
                onSubmit={handleSubmit(handleUpdateUser)}
                onPasswordSubmit={handleSubmit(handleUpdatePassword)}
                control={control}
                errors={errors}
                handleCancel={handleCancel}
                watch={watch}
                reset={reset}
                isSubmitting={isSubmitting}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
            />
        </Box>
    );
};

export default ProfileFormContainer;
