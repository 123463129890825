import axios, { AxiosError } from 'axios';
import { authStore } from '../store/authStore';

type SignOutFn = (() => Promise<void>) | undefined;

const createApiClient = (token: string, signOut: SignOutFn, showToast?: (message: string) => void) => {
    const { resetAuthState } = authStore.getState();
    
    const client = axios.create({
        baseURL: `${import.meta.env.VITE_API_ENDPOINT}/api/user`,
        timeout: 4000,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
    });
    
    client.interceptors.response.use(
        response => response,
        async (error: AxiosError) => {
            const { response } = error;

            if (response?.status === 401 || response?.status === 403) {
                resetAuthState();
                if (signOut) {
                    if (showToast){
                        showToast('Vuelve a loguearte tu token expiro.');
                    }
                    await signOut();
                } else {
                    alert('Hubo un error de autenticación');
                }
            } else if (response?.status === 400) {
                console.error("Error de validación:", response.data);

            } else {
                console.error("Error en la petición:", error.message);
            }

            return Promise.reject(error);
        }
    );


    return client;
};

export default createApiClient;
