import { useTheme } from "@chakra-ui/react";

export const useColorByName = (name: string): { buttonColor: string, hoverColor: string } => {
    const theme = useTheme();
    const formattedName = name.toLowerCase();
    const buttonColor = theme.colors[formattedName]?.[500];
    const hoverColor = theme.colors[formattedName]?.[300];
    return {buttonColor, hoverColor}
};


