import React, { memo } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Text,
    Button,
    VStack,
} from "@chakra-ui/react";
import IconSelector from "./utils/iconsName";
import { useColorByName } from "./utils/getNameColor";

interface ProductCardProps {
  title: string;
  plan?: string | null | boolean;
  onClick: () => void;
  textButton: string;
  description?: string;
  linkProductActive?: (title: string) => void | undefined;
  href?: string;
}

const ProductCard: React.FC<ProductCardProps> = memo(
    ({
        title,
        onClick,
        textButton,
        description,
        linkProductActive,
        href,
    }) => {
        const {buttonColor, hoverColor} = useColorByName(title);
        const comingSoon =
      textButton === "Próximamente" || textButton === "Coming soon";

        return (
            <Card
                maxW="350px"
                w="350px"
                minW="200px"
                minH="270px"
                height="380px"
                p="24px"
                bg="white"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                borderRadius="16px"
                boxShadow="0 4px 6px rgba(0, 0, 0, 0.3)"
                _hover={
                    !comingSoon
                        ? { cursor: "pointer", bg: hoverColor }
                        : { bg: hoverColor, cursor: "not-allowed" }
                }

                onClick={() => {
                    if (!comingSoon && linkProductActive) {
                        linkProductActive(title);
                    }
                }}
            >
                <CardHeader 
                    p={0} 
                    border={`solid 1px ${buttonColor}`}
                    borderRadius="10px"
                    width="100%" 
                    maxW="310px"
                    minW="256px"
                    height="158px"
                    display="flex" 
                    alignItems="center"
                    justifyContent="center" 
                    bg="white"
                >
                    {IconSelector({ name: title })}
                </CardHeader>

                <CardBody>
                    <VStack spacing={2} align="center" mt="12px" mb="15px">
                        <Text fontSize="sm" color="gray.600" textAlign="left">
                            {description}
                        </Text>
                    </VStack>
                </CardBody>

                <CardFooter p={0} w="100%">
                    <Button
                        mt={3}
                        bg={buttonColor}
                        color="white"
                        width="100%"
                        as={href ? "a" : undefined}
                        href={href}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!comingSoon) {
                                onClick();
                            }
                        }}
                    
                        _hover={{ background: buttonColor }}
                        disabled={comingSoon}
                    >
                        {textButton}
                    </Button>
                </CardFooter>
            </Card>
        );
    }
);

export default ProductCard;
