import {
    Box,
    VStack,
    Heading,
    Center,
    Flex,
} from "@chakra-ui/react";
import { Button } from "@canaia/ui-kit";

import RobotIcon from "../../icons/sidebarIcons/Robot"

const WelcomePage = () => {
    return (
        <Box minH="100%" display="flex" flexDirection="column">
            <Center flex="1" px={4}>
                <VStack spacing={8} textAlign="center">
                    <RobotIcon width={100} height={100}/>
                    <Heading as="h1" size="lg" color="black" maxW="525px" mb="100px">
            Bienvenido a Canaia, vamos a preparar todo para que puedas empezar
            a disfrutar de tu tiempo.
                    </Heading>

                    <Flex width="100%" justifyContent="space-between">
                        <Button
                            color="primary.500"
                            type="surface"
                            size="lg"
                            onClick={()=>{console.log('hice click en ver tutorial')}}
                            text="Ver tutorial"
                        />
                        <Button
                            color="primary.500"
                            size="lg"
                            onClick={()=>{console.log('hice click en crear una organizacion')}}
                            text=" Crea tu organización"
                        />
                     
                    </Flex>
                </VStack>
            </Center>

        </Box>
    );
};

export default WelcomePage;
