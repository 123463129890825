import React from 'react';

interface adSvgProps extends React.SVGAttributes<SVGElement> {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const adSvg: React.FC<adSvgProps> = ({
    width = 96,
    height = 42,
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 96 42" fill="none">
        <path d="M63.6507 31.1937H49.9146V38.0618C49.9146 38.7028 49.6938 39.2468 49.2521 39.6885C48.8104 40.1302 48.2663 40.3511 47.6253 40.3511C46.9843 40.3511 46.4402 40.1302 45.9985 39.6885C45.5568 39.2468 45.3359 38.7028 45.3359 38.0618V19.747C45.3359 18.1579 45.6322 16.6712 46.2301 15.2815C46.8281 13.8917 47.6414 12.6797 48.6811 11.6401C49.7207 10.6004 50.9327 9.78703 52.3225 9.18911C53.7122 8.59658 55.199 8.29492 56.788 8.29492C58.3771 8.29492 59.8638 8.59119 61.2536 9.18911C62.6434 9.78703 63.8554 10.6004 64.895 11.6401C65.9346 12.6797 66.748 13.8917 67.3459 15.2815C67.9438 16.6712 68.2401 18.1579 68.2401 19.747V38.0618C68.2401 38.7028 68.0193 39.2468 67.5776 39.6885C67.1358 40.1302 66.5918 40.3511 65.9508 40.3511C65.3098 40.3511 64.7657 40.1302 64.324 39.6885C63.8823 39.2468 63.6614 38.7028 63.6614 38.0618V31.1937H63.6507ZM63.6507 19.747C63.6507 18.799 63.4675 17.9102 63.1012 17.0698C62.7349 16.2295 62.2447 15.5077 61.636 14.8936C61.0273 14.2849 60.3001 13.7947 59.4598 13.4284C58.6195 13.0621 57.7253 12.879 56.7826 12.879C55.84 12.879 54.9404 13.0621 54.1055 13.4284C53.2651 13.7947 52.5379 14.2849 51.9292 14.8936C51.3205 15.5023 50.8304 16.2295 50.4641 17.0698C50.0978 17.9102 49.9146 18.8043 49.9146 19.747V26.615H63.6507V19.747Z" fill="#333333"/>
        <path d="M95.2465 38.062C95.2465 38.703 95.0256 39.2471 94.5839 39.6888C94.1422 40.1305 93.5981 40.3513 92.9571 40.3513C92.3161 40.3513 91.772 40.1305 91.3303 39.6888C90.8886 39.2471 90.6678 38.703 90.6678 38.062C89.6928 38.7623 88.6208 39.3225 87.4627 39.7319C86.3046 40.1466 85.0818 40.3513 83.7998 40.3513C82.2107 40.3513 80.724 40.0551 79.3342 39.4571C77.9444 38.8592 76.7324 38.0458 75.6928 37.0062C74.6532 35.9666 73.8398 34.7546 73.2418 33.3648C72.6439 31.975 72.3477 30.4883 72.3477 28.8993C72.3477 27.3102 72.6439 25.8235 73.2418 24.4337C73.8398 23.0439 74.6532 21.8319 75.6928 20.7923C76.7324 19.7527 77.9444 18.9393 79.3342 18.3413C80.724 17.7488 82.2107 17.4472 83.7998 17.4472C85.0818 17.4472 86.3046 17.6519 87.4627 18.0666C88.6208 18.4814 89.6928 19.0362 90.6678 19.7365V8.30057C90.6678 7.65956 90.8886 7.1155 91.3303 6.67379C91.772 6.23208 92.3161 6.01123 92.9571 6.01123C93.5981 6.01123 94.1422 6.23208 94.5839 6.67379C95.0256 7.1155 95.2465 7.65956 95.2465 8.30057V38.062ZM83.7998 35.7727C84.7478 35.7727 85.6366 35.5895 86.4769 35.2232C87.3173 34.8569 88.0391 34.3667 88.6532 33.758C89.2672 33.1493 89.752 32.4221 90.1183 31.5818C90.4846 30.7415 90.6678 29.8473 90.6678 28.9046C90.6678 27.962 90.4846 27.0678 90.1183 26.2275C89.752 25.3871 89.2618 24.6653 88.6532 24.0512C88.0445 23.4425 87.3173 22.9524 86.4769 22.5861C85.6366 22.2198 84.7424 22.0366 83.7998 22.0366C82.8571 22.0366 81.9629 22.2198 81.1226 22.5861C80.2822 22.9524 79.555 23.4425 78.9464 24.0512C78.3377 24.6599 77.8475 25.3871 77.4812 26.2275C77.1149 27.0678 76.9317 27.962 76.9317 28.9046C76.9317 29.8473 77.1149 30.7469 77.4812 31.5818C77.8475 32.4221 78.3377 33.144 78.9464 33.758C79.555 34.3667 80.2822 34.8569 81.1226 35.2232C81.9629 35.5895 82.8571 35.7727 83.7998 35.7727Z" fill="#333333"/>
        <path d="M16.6065 22.7432C18.0999 22.7432 19.3106 21.5325 19.3106 20.0391C19.3106 18.5456 18.0999 17.335 16.6065 17.335C15.113 17.335 13.9023 18.5456 13.9023 20.0391C13.9023 21.5325 15.113 22.7432 16.6065 22.7432Z" fill="#694A38"/>
        <path d="M24.0127 22.7432C25.5061 22.7432 26.7168 21.5325 26.7168 20.0391C26.7168 18.5456 25.5061 17.335 24.0127 17.335C22.5193 17.335 21.3086 18.5456 21.3086 20.0391C21.3086 21.5325 22.5193 22.7432 24.0127 22.7432Z" fill="#694A38"/>
        <path d="M31.4151 22.7432C32.9085 22.7432 34.1192 21.5325 34.1192 20.0391C34.1192 18.5456 32.9085 17.335 31.4151 17.335C29.9216 17.335 28.7109 18.5456 28.7109 20.0391C28.7109 21.5325 29.9216 22.7432 31.4151 22.7432Z" fill="#694A38"/>
        <path d="M33.6021 30.0254C32.4494 29.1635 30.8065 29.2443 29.8153 30.2893C28.878 31.2751 27.8007 32.11 26.5833 32.8049C24.6333 33.9146 22.4948 34.4694 20.1624 34.4694C18.2663 34.4694 16.494 34.087 14.8349 33.322C13.1758 32.5571 11.7268 31.5175 10.4933 30.2085C9.25433 28.8996 8.27395 27.3698 7.54136 25.6191C6.81416 23.8684 6.44786 21.9938 6.44786 19.99C6.44786 17.9861 6.81416 16.1116 7.54136 14.3609C8.26856 12.6156 9.25433 11.0858 10.4933 9.77145C11.7322 8.46248 13.1812 7.42285 14.8349 6.65794C16.494 5.89304 18.2663 5.51058 20.1624 5.51058C22.4948 5.51058 24.6333 6.06541 26.5833 7.17507C27.8007 7.86995 28.878 8.70489 29.8153 9.69065C30.8118 10.7303 32.4494 10.8165 33.6021 9.9546C35.0458 8.87726 35.1697 6.76029 33.8715 5.51058C32.514 4.20162 30.9788 3.09196 29.2551 2.17622C26.5079 0.727203 23.4752 0 20.1624 0C17.4313 0 14.8619 0.527895 12.4594 1.58369C10.0569 2.63948 7.96152 4.06695 6.17853 5.87149C4.39554 7.67603 2.98422 9.78761 1.94459 12.2062C0.904963 14.6302 0.387841 17.2212 0.387841 19.99C0.387841 22.7588 0.904963 25.3551 1.94459 27.7738C2.40246 28.8403 2.94113 29.8369 3.54444 30.7849L0 42L12.1362 38.2455C12.2439 38.294 12.3463 38.3532 12.4594 38.4017C14.8619 39.4575 17.4313 39.9854 20.1624 39.9854C23.4752 39.9854 26.5079 39.2582 29.2605 37.7984C30.9788 36.888 32.5194 35.7784 33.8769 34.464C35.1751 33.2143 35.0512 31.0973 33.6075 30.02L33.6021 30.0254Z" fill="#694A38"/>
    </svg>
);

export default adSvg;
