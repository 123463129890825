const colors = {
    primary: {
        100: "#EEF5F7",
        200: "#8BBFCB",
        300: "#67ABBB",
        400: "#4A94A6",
        500: "#3B7684",
        600: "#2B555F"
    },
    secondary: {
        500: "#38ADC7",
        700: "#2D9DB2",
    },
    blue: {
        50: "#38ADC7",
        100: "#0C56C0",
        500: "#094192",
        600: "#062B60",
        700: "#094192",
        800: "#051a39"
    },
    grey: {
        50: "#F7F7F7",           
        100: "#999",              
        500: "#ECECEC"          
    },
    green: {
        100: "#34C759",
        300: "#269642"
    },
    elevate: {
        200: "#FFF8FA",
        300: "#FFD6E2",
        400: "#FFA3BF",
        500: "#FF6F9A",
        600: "#FF3D77",
        700: "#FF0A53"
    },
    assist: {
        200: "#F9FCFE",
        300: "#B4D8F4",
        400: "#87C1ED",
        500: "#5AA9E6",
        600: "#2F93DF",
        700: "#1D79BE"
    },
    play: {
        300: "#FEE2DC",
        400: "#FCB8AB",
        500: "#FB8E79",
        600: "#FA6448",
        700: "#F93916"
    },
    talent: {
        300: "#D4BBD8",
        400: "#AB7AB3",
        500: "#965BA0",
        600: "#77487F",
        700: "#58365E",
    },
    collect: {
        300: "#95DAB8",
        400: "#6FCD9E",
        500: "#48BF84",
        600: "#389F6C",
        700: "#F93916"
    },
    ad: {
        300: "#AA7A60",
        400: "#8C624B",
        500: "#694A38",
        600: "#493427",
        700: "#281C15"
    },
    black: {
        40: "#EFEFEF",
        50: "#D7D7D7",
        100: "#C1C1C1",
        200: "#999999",
        300: "#999999",
        400: "#666666",
        500: "#333333",
        600: "#222222",
    },
    white: {
        50: "#EFEFEF",
        100: "#FFFFFF"
    },
    success: {
        200: "#C6F6D5",
        300: "#2CD888",
        400: "#21B06E",
        500: "#198754",
        600: "#115A38"
    },
    danger: {
        200: "#FED7D7",
        300: "#F81C34",
        400: "#DB071E",
        500: "#CE0015",
        600: "#C10004",
    },
    warning: {
        200: "#FEEBCB",
        300: "#FFCF70",
        400: "#FFBE3D",
        500: "#FFAE0C",
        600: "#D68F00"
    },
    info: {
        200: "#BEE3F8",
        300: "#47AFFF",
        400: "#1499FF",
        500: "#007EDF",
        600: "#0062AD"
    }
};
  
export default colors;
  