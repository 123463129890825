import React from 'react';

interface playSvgProps extends React.SVGAttributes<SVGElement> {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const playSvg: React.FC<playSvgProps> = ({
    width = 133,
    height = 50,
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 133 50" fill="none">
        <path d="M16.6762 22.8368C18.1758 22.8368 19.3915 21.6211 19.3915 20.1215C19.3915 18.6219 18.1758 17.4062 16.6762 17.4062C15.1766 17.4062 13.9609 18.6219 13.9609 20.1215C13.9609 21.6211 15.1766 22.8368 16.6762 22.8368Z" fill="#FB8E79"/>
        <path d="M24.1137 22.8368C25.6133 22.8368 26.829 21.6211 26.829 20.1215C26.829 18.6219 25.6133 17.4062 24.1137 17.4062C22.6141 17.4062 21.3984 18.6219 21.3984 20.1215C21.3984 21.6211 22.6141 22.8368 24.1137 22.8368Z" fill="#FB8E79"/>
        <path d="M31.5434 22.8368C33.043 22.8368 34.2587 21.6211 34.2587 20.1215C34.2587 18.6219 33.043 17.4062 31.5434 17.4062C30.0438 17.4062 28.8281 18.6219 28.8281 20.1215C28.8281 21.6211 30.0438 22.8368 31.5434 22.8368Z" fill="#FB8E79"/>
        <path d="M33.7408 30.1493C32.5833 29.2838 30.9336 29.365 29.9383 30.4143C28.9972 31.4041 27.9154 32.2425 26.693 32.9403C24.7349 34.0545 22.5876 34.6116 20.2456 34.6116C18.3416 34.6116 16.5621 34.2276 14.8961 33.4595C13.2302 32.6915 11.7752 31.6475 10.5366 30.3332C9.29251 29.0188 8.30809 27.4827 7.57247 25.7248C6.84227 23.9669 6.47447 22.0846 6.47447 20.0725C6.47447 18.0604 6.84227 16.178 7.57247 14.4202C8.30268 12.6677 9.29251 11.1315 10.5366 9.81176C11.7806 8.4974 13.2356 7.45348 14.8961 6.68541C16.5621 5.91735 18.3416 5.53331 20.2456 5.53331C22.5876 5.53331 24.7349 6.09043 26.693 7.20467C27.9154 7.90242 28.9972 8.7408 29.9383 9.73063C30.939 10.7745 32.5833 10.8611 33.7408 9.99567C35.1904 8.91388 35.3148 6.78818 34.0112 5.53331C32.6482 4.21895 31.1066 3.10471 29.3758 2.1852C26.6172 0.730203 23.572 0 20.2456 0C17.5032 0 14.9232 0.530073 12.5108 1.59022C10.0984 2.65037 7.99437 4.08373 6.20402 5.89571C4.41367 7.7077 2.99654 9.82799 1.95262 12.2566C0.908697 14.6906 0.389442 17.2923 0.389442 20.0725C0.389442 22.8526 0.908697 25.4597 1.95262 27.8883C2.41237 28.9593 2.95327 29.96 3.55906 30.9119L0 42.1733L12.1863 38.4033C12.2945 38.4519 12.3972 38.5114 12.5108 38.5601C14.9232 39.6203 17.5032 40.1503 20.2456 40.1503C23.572 40.1503 26.6172 39.4201 29.3812 37.9543C31.1066 37.0402 32.6536 35.926 34.0166 34.6062C35.3202 33.3513 35.1958 31.2256 33.7462 30.1439L33.7408 30.1493Z" fill="#FB8E79"/>
        <path d="M49.5507 28.8455V36.2016C49.5507 36.8453 49.3289 37.3916 48.8854 37.8351C48.4419 38.2786 47.8956 38.5004 47.2519 38.5004C46.6083 38.5004 46.062 38.2786 45.6184 37.8351C45.1749 37.3916 44.9531 36.8453 44.9531 36.2016V8.61617C44.9531 7.97251 45.1749 7.42621 45.6184 6.98268C46.062 6.53915 46.6083 6.31738 47.2519 6.31738H56.6796C58.2428 6.31738 59.7032 6.61487 61.0717 7.21526C62.4347 7.81024 63.6247 8.61617 64.6362 9.62764C65.6476 10.6391 66.4535 11.8291 67.0485 13.1921C67.6435 14.5552 67.9464 16.021 67.9464 17.5841C67.9464 19.1473 67.6489 20.6131 67.0485 21.9762C66.4535 23.3392 65.6476 24.5292 64.6362 25.5407C63.6247 26.5521 62.4347 27.358 61.0717 27.953C59.7086 28.548 58.2428 28.8509 56.6796 28.8509H49.5507V28.8455ZM56.6796 24.2479C57.5992 24.2479 58.4646 24.0694 59.2759 23.7178C60.0873 23.3663 60.7958 22.8903 61.3908 22.2953C61.9858 21.6949 62.4618 20.9918 62.8134 20.1804C63.1649 19.3691 63.3434 18.5037 63.3434 17.5841C63.3434 16.6646 63.1649 15.7992 62.8134 14.9879C62.4618 14.1765 61.9858 13.4734 61.3908 12.873C60.7958 12.278 60.0873 11.802 59.2759 11.4504C58.4646 11.0989 57.5992 10.9204 56.6796 10.9204H49.5507V24.2533H56.6796V24.2479Z" fill="#333333"/>
        <path d="M72.9961 6.31734C72.9961 5.67368 73.2179 5.12738 73.6614 4.68385C74.1049 4.24032 74.6512 4.01855 75.2949 4.01855C75.9385 4.01855 76.4848 4.24032 76.9284 4.68385C77.3719 5.12738 77.5937 5.67368 77.5937 6.31734V36.2016C77.5937 36.8452 77.3719 37.3915 76.9284 37.8351C76.4848 38.2786 75.9385 38.5004 75.2949 38.5004C74.6512 38.5004 74.1049 38.2786 73.6614 37.8351C73.2179 37.3915 72.9961 36.8452 72.9961 36.2016V6.31734Z" fill="#333333"/>
        <path d="M100.122 36.1584C99.1433 36.894 98.0669 37.4619 96.904 37.8622C95.741 38.2625 94.5132 38.4572 93.2259 38.4572C91.6303 38.4572 90.1374 38.1597 88.7419 37.5593C87.3464 36.9589 86.1294 36.1422 85.0855 35.0982C84.0416 34.0543 83.2248 32.8373 82.6244 31.4418C82.0295 30.0463 81.7266 28.5535 81.7266 26.9578C81.7266 25.3622 82.0241 23.8694 82.6244 22.4739C83.2248 21.0784 84.0416 19.8613 85.0855 18.8174C86.1294 17.7735 87.3464 16.9568 88.7419 16.3564C90.1374 15.7614 91.6303 15.4585 93.2259 15.4585C94.8215 15.4585 96.3144 15.756 97.7099 16.3564C99.1054 16.9568 100.322 17.7735 101.366 18.8174C102.41 19.8613 103.227 21.0784 103.827 22.4739C104.428 23.8694 104.725 25.3622 104.725 26.9578V36.1963C104.725 36.8399 104.503 37.3862 104.06 37.8298C103.616 38.2733 103.07 38.495 102.426 38.495C101.783 38.495 101.237 38.2733 100.793 37.8298C100.349 37.3862 100.128 36.8399 100.128 36.1963V36.153L100.122 36.1584ZM93.2259 33.8596C94.1779 33.8596 95.0703 33.6757 95.9141 33.3079C96.7579 32.9401 97.4827 32.4479 98.0993 31.8367C98.716 31.2255 99.2027 30.4953 99.5706 29.6515C99.9384 28.8077 100.122 27.9098 100.122 26.9632C100.122 26.0167 99.9384 25.1188 99.5706 24.275C99.2027 23.4312 98.7105 22.7064 98.0993 22.0898C97.4881 21.4786 96.7579 20.9864 95.9141 20.6186C95.0703 20.2508 94.1725 20.0669 93.2259 20.0669C92.2793 20.0669 91.3761 20.2508 90.5377 20.6186C89.6939 20.9864 88.9637 21.4786 88.3525 22.0898C87.7413 22.701 87.2491 23.4312 86.8813 24.275C86.5134 25.1188 86.3295 26.0167 86.3295 26.9632C86.3295 27.9098 86.5134 28.8131 86.8813 29.6515C87.2491 30.4953 87.7413 31.2201 88.3525 31.8367C88.9637 32.4479 89.6939 32.9401 90.5377 33.3079C91.3815 33.6757 92.2739 33.8596 93.2259 33.8596Z" fill="#333333"/>
        <path d="M118.604 36.2508L109.912 18.8287C109.636 18.277 109.598 17.7037 109.798 17.1033C109.999 16.5083 110.388 16.054 110.972 15.7457C111.524 15.4698 112.097 15.4319 112.698 15.6321C113.298 15.8322 113.747 16.2216 114.055 16.8058L121.179 31.0583L128.302 16.7571C128.611 16.2054 129.06 15.8322 129.66 15.6321C130.26 15.4319 130.85 15.4698 131.429 15.7457C131.98 16.054 132.354 16.5029 132.554 17.1033C132.754 17.7037 132.716 18.2933 132.44 18.872L120.07 43.6556C119.091 45.5542 117.696 47.0903 115.889 48.2532C114.082 49.4161 112.043 50.0003 109.771 50.0003C109.128 50.0003 108.581 49.7785 108.138 49.335C107.694 48.8915 107.473 48.3452 107.473 47.7015C107.473 47.0578 107.694 46.5115 108.138 46.068C108.581 45.6245 109.128 45.4027 109.771 45.4027C110.415 45.4027 110.999 45.3108 111.61 45.1269C112.222 44.943 112.8 44.6887 113.336 44.3696C113.871 44.0505 114.347 43.6881 114.758 43.2878C115.17 42.8876 115.499 42.4603 115.748 42.0005L118.599 36.2562L118.604 36.2508Z" fill="#333333"/>
    </svg>
);

export default playSvg;
