import createApiClient from '../ApiClients';

interface UpdateUserData {
    name: string,
    last_name: string,
    phone: string,
    address: string,
    city: string,
    postal_code: string,
    country: string,
    language: string
}
interface UpdatePasswordData {
    old_password: string;
    new_password: string;
}

export const updateUser = async (token: string, data: UpdateUserData, signOut: () => Promise<void>) => {
    try {
        const apiClient = createApiClient(token, signOut);
        const response = await apiClient.patch('', data);
        return response.data;
    } catch (error) {
        console.error("Error updating user:", error);
        throw error; 
    }
};

export const updatePassword = async (token: string, data: UpdatePasswordData, signOut: () => Promise<void>) => {
    try {
        const apiClient = createApiClient(token, signOut);
        const response = await apiClient.patch('/password', data);
        return response.data;
    } catch (error) {
        console.error("Error updating password:", error);
        throw error;
    }
};

export const getUser = async (token: string, signOut: () => Promise<void>) => {
    try {
        const apiClient = createApiClient(token, signOut);
        const response = await apiClient.get('');
        return response.data;
    } catch (error) {
        console.error("Error getting user:", error);
        throw error;
    }
}
