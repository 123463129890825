import { Outlet, Navigate } from "react-router-dom";
import { useLogto } from "@logto/react";
import { useEffect, useState } from "react";
import { authStore } from "../store/authStore";

interface ProtectedRouteProps {
    restrictedOnly: boolean; // true = only for users without organizationId
}

const ProtectedRoute = ({ restrictedOnly }: ProtectedRouteProps) => {
    const { signIn, isAuthenticated } = useLogto();
    const [isLoading, setIsLoading] = useState(true);
    const [organizationId, setOrganizationId] = useState<string | null | undefined>(undefined);

    useEffect(() => {
        const unsubscribe = authStore.subscribe(() => {
            const updatedOrganizationId = authStore.getState().organizationId;
            setOrganizationId(updatedOrganizationId);
        });

        const initialOrganizationId = authStore.getState().organizationId;
        setOrganizationId(initialOrganizationId);

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated !== undefined && organizationId !== undefined) {
            setIsLoading(false);
        }
    }, [isAuthenticated, organizationId]);

    if (isLoading) return <div>Loading...</div>;

    if (!isAuthenticated) {
        signIn(window.location.origin + "/sign-in-callback");
        return null;
    }
    if (!restrictedOnly && !organizationId) {
        return <Navigate to="/welcome" replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
