import React from 'react';

interface IconProps {
    width?: number;
    height?: number;
    color?: string;
}

const IconFiCreditCard: React.FC<IconProps> = ({ width = 24, height = 24, color = '#666666'}) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="FiCreditCard">
            <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M4.5 6.66667C4.03976 6.66667 3.66667 7.03976 3.66667 7.5V17.5C3.66667 17.9602 4.03976 18.3333 4.5 18.3333H19.5C19.9602 18.3333 20.3333 17.9602 20.3333 17.5V7.5C20.3333 7.03976 19.9602 6.66667 19.5 6.66667H4.5ZM2 7.5C2 6.11929 3.11929 5 4.5 5H19.5C20.8807 5 22 6.11929 22 7.5V17.5C22 18.8807 20.8807 20 19.5 20H4.5C3.11929 20 2 18.8807 2 17.5V7.5Z" fill={color}/>
            <path id="Vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M2 11C2 10.4477 2.3731 10 2.83333 10H21.1667C21.6269 10 22 10.4477 22 11C22 11.5523 21.6269 12 21.1667 12H2.83333C2.3731 12 2 11.5523 2 11Z" fill={color}/>
        </g>
    </svg>
);

export default IconFiCreditCard;
