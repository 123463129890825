import React from 'react';

interface collectSvgProps extends React.SVGAttributes<SVGElement> {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const collectSvg: React.FC<collectSvgProps> = ({
    width = 181,
    height = 42,
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 181 42" fill="none">
        <path d="M16.6065 22.7427C18.0999 22.7427 19.3106 21.532 19.3106 20.0386C19.3106 18.5451 18.0999 17.3345 16.6065 17.3345C15.113 17.3345 13.9023 18.5451 13.9023 20.0386C13.9023 21.532 15.113 22.7427 16.6065 22.7427Z" fill="#48BF84"/>
        <path d="M24.0127 22.7427C25.5062 22.7427 26.7168 21.532 26.7168 20.0386C26.7168 18.5451 25.5062 17.3345 24.0127 17.3345C22.5193 17.3345 21.3086 18.5451 21.3086 20.0386C21.3086 21.532 22.5193 22.7427 24.0127 22.7427Z" fill="#48BF84"/>
        <path d="M31.4151 22.7427C32.9085 22.7427 34.1192 21.532 34.1192 20.0386C34.1192 18.5451 32.9085 17.3345 31.4151 17.3345C29.9216 17.3345 28.7109 18.5451 28.7109 20.0386C28.7109 21.532 29.9216 22.7427 31.4151 22.7427Z" fill="#48BF84"/>
        <path d="M33.6022 30.0254C32.4494 29.1635 30.8065 29.2443 29.8153 30.2893C28.878 31.2751 27.8007 32.11 26.5833 32.8049C24.6333 33.9146 22.4948 34.4694 20.1624 34.4694C18.2663 34.4694 16.494 34.087 14.8349 33.322C13.1758 32.5571 11.7268 31.5175 10.4933 30.2085C9.25433 28.8996 8.27395 27.3698 7.54136 25.6191C6.81416 23.8684 6.44786 21.9938 6.44786 19.99C6.44786 17.9861 6.81416 16.1116 7.54136 14.3609C8.26856 12.6156 9.25433 11.0858 10.4933 9.77145C11.7322 8.46248 13.1812 7.42285 14.8349 6.65794C16.494 5.89304 18.2663 5.51058 20.1624 5.51058C22.4948 5.51058 24.6333 6.06541 26.5833 7.17507C27.8007 7.86995 28.878 8.70489 29.8153 9.69065C30.8118 10.7303 32.4494 10.8165 33.6022 9.9546C35.0458 8.87726 35.1697 6.76029 33.8715 5.51058C32.514 4.20162 30.9788 3.09196 29.2551 2.17622C26.5079 0.727203 23.4752 0 20.1624 0C17.4313 0 14.8619 0.527895 12.4594 1.58369C10.0569 2.63948 7.96152 4.06695 6.17853 5.87149C4.39554 7.67603 2.98422 9.78761 1.94459 12.2062C0.904963 14.6302 0.387841 17.2212 0.387841 19.99C0.387841 22.7588 0.904963 25.3551 1.94459 27.7738C2.40246 28.8403 2.94113 29.8369 3.54444 30.7849L0 42L12.1362 38.2455C12.2439 38.294 12.3463 38.3532 12.4594 38.4017C14.8619 39.4575 17.4313 39.9854 20.1624 39.9854C23.4752 39.9854 26.5079 39.2582 29.2605 37.7984C30.9788 36.888 32.5194 35.7784 33.8769 34.464C35.1751 33.2143 35.0512 31.0973 33.6075 30.02L33.6022 30.0254Z" fill="#48BF84"/>
        <path d="M45.418 19.6562C45.418 18.0671 45.7142 16.5804 46.3122 15.1906C46.9101 13.8009 47.7235 12.5889 48.7631 11.5492C49.8027 10.5096 51.0147 9.69621 52.4045 9.09829C53.7943 8.50576 55.281 8.2041 56.8701 8.2041C58.4591 8.2041 59.9405 8.50037 61.3141 9.09829C62.6877 9.69621 63.9105 10.5096 64.977 11.5492C65.4026 12.0071 65.618 12.5512 65.618 13.176C65.618 13.8009 65.4026 14.3449 64.977 14.8028C64.5192 15.2283 63.9697 15.4438 63.3287 15.4438C62.6877 15.4438 62.1544 15.2283 61.7289 14.8028C61.1202 14.1941 60.393 13.7039 59.5526 13.3376C58.7123 12.9713 57.8181 12.7882 56.8755 12.7882C55.9328 12.7882 55.0332 12.9713 54.1983 13.3376C53.3579 13.7039 52.6307 14.1941 52.022 14.8028C51.4134 15.4115 50.9232 16.1387 50.5569 16.979C50.1906 17.8193 50.0074 18.7135 50.0074 19.6562V28.8136C50.0074 29.7616 50.1906 30.6558 50.5569 31.4908C50.9232 32.3311 51.4134 33.0529 52.022 33.667C52.6307 34.2757 53.3579 34.7659 54.1983 35.1322C55.0386 35.4984 55.9274 35.6816 56.8755 35.6816C57.8235 35.6816 58.7123 35.4984 59.5526 35.1322C60.393 34.7659 61.1148 34.2757 61.7289 33.667C62.1867 33.2091 62.7308 32.9829 63.3556 32.9829C63.9805 32.9829 64.5245 33.2091 64.9824 33.667C65.408 34.0925 65.6234 34.6312 65.6234 35.2722C65.6234 35.9132 65.408 36.4465 64.9824 36.8721C63.9428 37.9386 62.7308 38.7736 61.341 39.3661C59.9513 39.9586 58.4645 40.2603 56.8755 40.2603C55.2864 40.2603 53.7997 39.964 52.4099 39.3661C51.0201 38.7682 49.8081 37.9548 48.7685 36.9151C47.7289 35.8755 46.9155 34.6635 46.3175 33.2737C45.725 31.884 45.4234 30.3973 45.4234 28.8082V19.6508L45.418 19.6562Z" fill="#333333"/>
        <path d="M80.3076 40.2601C78.7185 40.2601 77.2318 39.9639 75.842 39.366C74.4522 38.768 73.2402 37.9546 72.2006 36.915C71.161 35.8754 70.3476 34.6634 69.7497 33.2736C69.1571 31.8839 68.8555 30.3971 68.8555 28.8081C68.8555 27.219 69.1517 25.7323 69.7497 24.3425C70.3476 22.9527 71.161 21.7407 72.2006 20.7011C73.2402 19.6615 74.4522 18.8481 75.842 18.2501C77.2318 17.6576 78.7185 17.356 80.3076 17.356C81.8966 17.356 83.3834 17.6522 84.7731 18.2501C86.1629 18.8481 87.3749 19.6615 88.4145 20.7011C89.4542 21.7407 90.2675 22.9527 90.8655 24.3425C91.4634 25.7323 91.7597 27.219 91.7597 28.8081C91.7597 30.3971 91.4634 31.8839 90.8655 33.2736C90.2675 34.6634 89.4542 35.8754 88.4145 36.915C87.3749 37.9546 86.1629 38.768 84.7731 39.366C83.3834 39.9585 81.8966 40.2601 80.3076 40.2601ZM80.3076 35.6815C81.2556 35.6815 82.1444 35.4983 82.9848 35.132C83.8251 34.7657 84.5469 34.2755 85.161 33.6668C85.7697 33.0581 86.2599 32.3309 86.6261 31.4906C86.9924 30.6503 87.1756 29.7561 87.1756 28.8134C87.1756 27.8708 86.9924 26.9766 86.6261 26.1363C86.2599 25.2959 85.7697 24.5741 85.161 23.96C84.5523 23.3513 83.8251 22.8611 82.9848 22.4949C82.1444 22.1286 81.2502 21.9454 80.3076 21.9454C79.3649 21.9454 78.4653 22.1286 77.6304 22.4949C76.7901 22.8611 76.0629 23.3513 75.4542 23.96C74.8455 24.5687 74.3553 25.2959 73.989 26.1363C73.6227 26.9766 73.4395 27.8708 73.4395 28.8134C73.4395 29.7561 73.6227 30.6557 73.989 31.4906C74.3553 32.3309 74.8455 33.0528 75.4542 33.6668C76.0629 34.2755 76.7901 34.7657 77.6304 35.132C78.4707 35.4983 79.3595 35.6815 80.3076 35.6815Z" fill="#333333"/>
        <path d="M96.7891 8.20926C96.7891 7.56825 97.0099 7.02419 97.4516 6.58248C97.8933 6.14078 98.4374 5.91992 99.0784 5.91992C99.7194 5.91992 100.263 6.14078 100.705 6.58248C101.147 7.02419 101.368 7.56825 101.368 8.20926V37.9707C101.368 38.6117 101.147 39.1558 100.705 39.5975C100.263 40.0392 99.7194 40.2601 99.0784 40.2601C98.4374 40.2601 97.8933 40.0392 97.4516 39.5975C97.0099 39.1558 96.7891 38.6117 96.7891 37.9707V8.20926Z" fill="#333333"/>
        <path d="M106.406 8.20926C106.406 7.56825 106.627 7.02419 107.069 6.58248C107.511 6.14078 108.055 5.91992 108.696 5.91992C109.337 5.91992 109.881 6.14078 110.322 6.58248C110.764 7.02419 110.985 7.56825 110.985 8.20926V37.9707C110.985 38.6117 110.764 39.1558 110.322 39.5975C109.881 40.0392 109.337 40.2601 108.696 40.2601C108.055 40.2601 107.511 40.0392 107.069 39.5975C106.627 39.1558 106.406 38.6117 106.406 37.9707V8.20926Z" fill="#333333"/>
        <path d="M120.099 31.1028C120.557 32.4441 121.376 33.5429 122.55 34.3994C123.724 35.2559 125.06 35.6814 126.558 35.6814C127.506 35.6814 128.394 35.4983 129.235 35.132C130.075 34.7657 130.797 34.2755 131.411 33.6668C131.837 33.2413 132.375 33.0258 133.011 33.0258C133.646 33.0258 134.201 33.2413 134.659 33.6668C135.085 34.1247 135.3 34.6688 135.3 35.2936C135.3 35.9185 135.085 36.4625 134.659 36.9204C133.593 37.96 132.37 38.7734 130.996 39.3713C129.623 39.9639 128.141 40.2655 126.558 40.2655C124.974 40.2655 123.482 39.9693 122.092 39.3713C120.702 38.7734 119.49 37.96 118.451 36.9204C117.411 35.8808 116.598 34.6688 116 33.279C115.402 31.8892 115.105 30.4025 115.105 28.8134C115.105 27.2244 115.402 25.7376 116 24.3479C116.592 22.9581 117.411 21.7461 118.451 20.7065C119.49 19.6668 120.702 18.8534 122.092 18.2555C123.482 17.663 124.968 17.3613 126.558 17.3613C128.147 17.3613 129.633 17.6576 131.023 18.2555C132.413 18.8534 133.625 19.6668 134.665 20.7065C135.704 21.7461 136.518 22.9581 137.115 24.3479C137.713 25.7376 138.01 27.2244 138.01 28.8134C138.01 29.4221 137.789 29.9554 137.347 30.4187C136.905 30.8765 136.361 31.1028 135.72 31.1028H120.104H120.099ZM133.011 26.5241C132.553 25.1828 131.734 24.0839 130.56 23.2274C129.386 22.3709 128.05 21.9454 126.552 21.9454C125.055 21.9454 123.719 22.3709 122.544 23.2274C121.37 24.0839 120.535 25.1828 120.05 26.5241H133.005H133.011Z" fill="#333333"/>
        <path d="M161.215 36.915C160.176 37.9546 158.964 38.768 157.574 39.366C156.184 39.9585 154.697 40.2601 153.108 40.2601C151.519 40.2601 150.033 39.9639 148.643 39.366C147.253 38.768 146.041 37.9546 145.001 36.915C143.962 35.8754 143.148 34.6634 142.55 33.2736C141.958 31.8839 141.656 30.3971 141.656 28.8081C141.656 27.219 141.953 25.7323 142.55 24.3425C143.148 22.9527 143.962 21.7407 145.001 20.7011C146.041 19.6615 147.253 18.8481 148.643 18.2501C150.033 17.6576 151.519 17.356 153.108 17.356C154.697 17.356 156.179 17.6522 157.547 18.2501C158.921 18.8481 160.143 19.6615 161.21 20.7011C161.635 21.159 161.851 21.703 161.851 22.3279C161.851 22.9527 161.635 23.4968 161.21 23.9546C160.752 24.3802 160.203 24.5957 159.562 24.5957C158.921 24.5957 158.387 24.3802 157.962 23.9546C157.353 23.346 156.626 22.8558 155.786 22.4895C154.945 22.1232 154.051 21.94 153.108 21.94C152.166 21.94 151.271 22.1232 150.431 22.4895C149.591 22.8558 148.869 23.346 148.255 23.9546C147.646 24.5633 147.156 25.2905 146.79 26.1309C146.423 26.9712 146.24 27.8654 146.24 28.8081C146.24 29.7507 146.423 30.6503 146.79 31.4852C147.156 32.3256 147.646 33.0474 148.255 33.6615C148.864 34.2702 149.591 34.7603 150.431 35.1266C151.271 35.4929 152.16 35.6761 153.108 35.6761C154.056 35.6761 154.945 35.4929 155.786 35.1266C156.626 34.7603 157.348 34.2702 157.962 33.6615C158.42 33.2359 158.964 33.0204 159.589 33.0204C160.213 33.0204 160.757 33.2359 161.215 33.6615C161.641 34.1193 161.856 34.6634 161.856 35.2882C161.856 35.9131 161.641 36.4571 161.215 36.915Z" fill="#333333"/>
        <path d="M167.852 10.4988C167.852 9.8578 168.072 9.31374 168.514 8.87203C168.956 8.43033 169.5 8.20947 170.141 8.20947C170.782 8.20947 171.326 8.43033 171.768 8.87203C172.209 9.31374 172.43 9.8578 172.43 10.4988V17.3668H176.093C176.734 17.3668 177.278 17.5877 177.72 18.0294C178.162 18.4711 178.383 19.0152 178.383 19.6562C178.383 20.2972 178.162 20.8413 177.72 21.283C177.278 21.7247 176.734 21.9455 176.093 21.9455H172.43V33.3922C172.43 34.0009 172.651 34.5342 173.093 34.9975C173.535 35.4553 174.079 35.6816 174.72 35.6816C175.361 35.6816 175.862 35.4553 176.325 34.9975C176.783 34.5396 177.332 34.308 177.973 34.308C178.614 34.308 179.147 34.5342 179.573 34.9975C180.031 35.423 180.262 35.9563 180.262 36.5973C180.262 37.2383 180.036 37.7878 179.573 38.2456C178.964 38.8543 178.237 39.3445 177.397 39.7108C176.556 40.0771 175.662 40.2603 174.72 40.2603C173.777 40.2603 172.883 40.0771 172.042 39.7108C171.202 39.3445 170.48 38.8543 169.866 38.2456C169.257 37.6369 168.767 36.9097 168.401 36.0694C168.035 35.2291 167.852 34.3349 167.852 33.3922V10.4988Z" fill="#333333"/>
    </svg>
);

export default collectSvg;
