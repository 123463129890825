import React from "react";
import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage, Heading,
    Text,
    Stack, HStack, VStack,
} from "@chakra-ui/react";
import {Input, PhotoUpload, ModalCustom, Button, Select} from '@canaia/ui-kit';
import {useTranslation} from 'react-i18next';
import {ProfileFormProps} from "./interfaces";
import {Controller} from "react-hook-form";
import {useNavigate} from "react-router-dom";

const ProfileForm: React.FC<ProfileFormProps> = (
    {
        onSubmit,
        onPasswordSubmit,
        control,
        errors,
        isSubmitting,
        watch,
        reset,
        setIsModalOpen,
        isModalOpen
    }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleOpen = () => setIsModalOpen(true);

    const handleClose = () => {
        setIsModalOpen(false);
        reset();
    };

    const handleSave = async () => {
        onPasswordSubmit();
    };

    const modalArgs = {
        backgroundColorModal: "white.100",
        title: "Cambio de contraseña",
        titleAlign: "left",
        fontSizeTitle: "2xl",
        disableOverlay: false,
        buttonAcceptTextColor: "white.100",
        buttonAcceptBackground: "primary.400",
        buttonAcceptVisible: true,
        buttonAcceptDisable: !!isSubmitting,
        buttonCancelVisible: true,
        buttonCancelDisable: !!isSubmitting,
        buttonCancelBorder: "primary.600",
        buttonCancelBackground: "white.100",
        buttonCancelText: "primary.600",
        maxWidth: '400px',
        isCenteredModal: true,
        onAcceptText: isSubmitting ? t("Saving...") : t("Aceptar"),
        onCancelText: 'Cancelar',
        onAcceptClick: handleSave,
    }

    return (
        <Box
            as="form"
            onSubmit={onSubmit}
            maxW="900px"
            mx="auto"
            py={8}
            px={4}
            borderRadius="md"
        >

            <Flex width={"100%"} alignItems={"center"}>
                <Stack spacing={4} mb={2} mr={4} flex="1" mt={2}>
                    <Heading as="h2" size="lg" mb={2} textAlign={"left"}>
                        {t("Información personal")}
                    </Heading>
                    <Text fontSize="lg" mb={6} color="gray.600" textAlign={"left"}>
                        {t("Puedes cambiar la configuración de tu cuenta aquí")}
                    </Text>
                </Stack>
                <Stack spacing={4} mb={2} mr={4} flex="1" mt={2} maxWidth={'220px'}>
                    <Button
                        text={"Cambiar Contraseña"}
                        color={"primary.500"}
                        textAlign={"left"}
                        type={"outline"}
                        size={"lg"}
                        loading={isSubmitting}
                        onClick={handleOpen}
                    ></Button>
                </Stack>
            </Flex>
            <Flex width="100%">
                <Stack spacing={4} mb={2} mr={4} flex="1" mt={2}>
                    <PhotoUpload
                        description={t("uploadYourProfilePhoto")}
                        iconColor="primary.500"
                        onUpload={(file: File) => console.log('Por ahora no se manda al formulario.', file)}
                    />
                </Stack>
                <Stack spacing={4} mb={2} flex="6">
                    <FormControl isInvalid={!!errors.name} mb={4}>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: t('Este campo no puede estar vacío'),
                            }}
                            render={({ field }) => (
                                <Input
                                    label="Nombre"
                                    type="text"
                                    placeholder={t('Nombre')}
                                    info={true}
                                    isRequired={true}
                                    infoText={"Este campo es para especificar el nombre del usuario."}
                                    product={"info"}
                                    description={"Nombre del usuario."}
                                    {...field}
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.last_name}>
                        <Controller
                            name="last_name"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <Input
                                    label="Apellido"
                                    type="text"
                                    placeholder={t('Apellido')}
                                    info={true}
                                    infoText={"Este campo es para especificar los apellidos del usuario."}
                                    product={"info"}
                                    description={"Añade tus apellidos."}
                                    {...field}
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.last_name?.message}</FormErrorMessage>
                    </FormControl>
                </Stack>
            </Flex>

            <VStack spacing={4} align="stretch" mt={6}>
                <HStack spacing={4} alignItems={"flex-end"}>
                    <FormControl isInvalid={!!errors.email} flex="1">
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <Input
                                    label="Email"
                                    type="email"
                                    placeholder={t('Email')}
                                    info={true}
                                    infoText={"Este campo muestra el email del usuario y no se puede modificar."}
                                    product={"info"}
                                    description={"Correo electrónico de usuario."}
                                    {...field}
                                    isDisabled
                                    height="45px"
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.phone} flex="1">
                        <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{
                                pattern: {
                                    value: /^\d{8,}$/,
                                    message: t("El teléfono debe contener al menos 8 dígitos numéricos"),
                                },
                            }}
                            render={({ field }) => (
                                <Input
                                    label="Teléfono"
                                    type="text"
                                    placeholder={t("Ejemplo: +52481195600")}
                                    info={true}
                                    infoText={t("Formato válido: '+' seguido de al menos 1 dígito para la lada y al menos 8 dígitos para el número")}
                                    product={"info"}
                                    description={t("Introduce tu número de teléfono con el formato correcto.")}
                                    height="45px"
                                    {...field}
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
                    </FormControl>
                </HStack>
            </VStack>

            <VStack spacing={4} align="stretch" mt={4}>
                <HStack spacing={4} alignItems="flex-end">
                    <FormControl isInvalid={!!errors.address} flex="1">
                        <Controller
                            name="address"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <Input
                                    label="Dirección"
                                    type="text"
                                    placeholder={t('Dirección')}
                                    info={true}
                                    infoText={"Este campo es para especificar la dirección."}
                                    product={"info"}
                                    description={"Introduce tu dirección."}
                                    {...field}
                                    height="45px"
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.address?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.city} flex="1">
                        <Controller
                            name="city"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <Input
                                    label="Población"
                                    type="text"
                                    placeholder={t('Población')}
                                    info={true}
                                    infoText={"Este campo es para especificar tu población."}
                                    product={"info"}
                                    description={"Introduce tu población."}
                                    {...field}
                                    height="45px"
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
                    </FormControl>
                </HStack>
            </VStack>

            <VStack spacing={4} align="stretch" mt={4}>
                <HStack spacing={4} alignItems="flex-end">
                    <FormControl isInvalid={!!errors.postal_code} flex="1">
                        <Controller
                            name="postal_code"
                            control={control}
                            defaultValue=""
                            rules={{
                                pattern: {
                                    value: /^[0-9]+$/, // Solo permite números
                                    message: t("El código postal solo debe contener números"),
                                },
                            }}
                            render={({ field }) => (
                                <Input
                                    label="Código postal"
                                    type="text"
                                    placeholder={t("Código Postal")}
                                    info={true}
                                    infoText={"Este campo es para especificar el código postal."}
                                    product={"info"}
                                    description={"Introduce tu código postal."}
                                    {...field}
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.postal_code?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.country} flex="1">
                        <Controller
                            name="country"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <Select
                                    placeholder={"País de residencia"}
                                    fontFamilyTooltip={"Sansation-Regular"}
                                    label={"Selecciona tu país de residencia"}
                                    isRequired={false}
                                    bgColorTooltip ={'info.200'}
                                    borderTooltip ={'1px solid info.300'}
                                    fontSizeTooltip = {"14px"}
                                    positionTooltip ={'top'}
                                    hasArrowTooltip = {true}
                                    options={[
                                        {name: "España", value: "España"},
                                        {name: "US", value: "Estados Unidos"},
                                    ]}
                                    {...field}
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.country?.message}</FormErrorMessage>
                    </FormControl>
                </HStack>
            </VStack>


            <Flex mt={6} justify="space-between">
                <Button
                    text={t("Volver")}
                    color={"primary.400"}
                    textAlign={"left"}
                    type={"outline"}
                    size={"lg"}
                    onClick={() => navigate(-1)}
                    disabled={isSubmitting}
                ></Button>
                <Button
                    text={t("Guardar")}
                    color={"primary.400"}
                    textAlign={"left"}
                    type={"solid"}
                    size={"lg"}
                    loading={isSubmitting}
                    onClick={onSubmit}
                    disabled={isSubmitting}
                ></Button>
            </Flex>

            <ModalCustom {...modalArgs} isOpen={isModalOpen} onClose={handleClose} onCancelClick={handleClose}>
                <Box display="flex" alignItems="start" justifyContent="start" flexDir="column">
                    <FormControl isInvalid={!!errors.old_password} mb={4}>
                        <Controller
                            name="old_password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: t("La contraseña actual es requerida"),
                            }}
                            render={({field}) => (
                                <Input
                                    label={t("Contraseña actual")}
                                    type={"password"}
                                    placeholder={t("Ingresa tu contraseña")}
                                    isRequired={true}
                                    {...field}
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.old_password?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.new_password} mb={4}>
                        <Controller
                            name="new_password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: t("La nueva contraseña es requerida"),
                                minLength: {
                                    value: 8,
                                    message: t("La contraseña debe contar con al menos 8 caracteres"),
                                },
                            }}
                            render={({field}) => (
                                <Input
                                    label={t("Nueva contraseña")}
                                    type="password"
                                    placeholder={t("Ingresa la nueva contraseña")}
                                    isRequired={true}
                                    {...field}
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.new_password?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.repeat_password} mb={4}>
                        <Controller
                            name="repeat_password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: t("Este campo es requerido"),
                                validate: (value) =>
                                    value === watch("new_password") || t("Las contraseñas no son iguales"),
                            }}
                            render={({field}) => (
                                <Input
                                    label={t("Repetir contraseña")}
                                    type="password"
                                    placeholder={t("Repite la nueva contraseña")}
                                    isRequired={true}
                                    {...field}
                                />
                            )}
                        />
                        <FormErrorMessage>{errors.repeat_password?.message}</FormErrorMessage>
                    </FormControl>
                </Box>
            </ModalCustom>
        </Box>
    );
};

export default ProfileForm;
