interface IconRobotProps {
    width?: number;
    height?: number;
    color?: string
}

const IconRobotHover: React.FC<IconRobotProps> = ({ width = 24, height = 24, color }) => (

    <svg width={width}
        height={height}
        viewBox="0 0 22 24"
        fill={color}  xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.7626 3.15604C11.6262 3.31919 12.4588 2.75379 12.6248 1.89021C12.7879 1.02664 12.2225 0.194002 11.359 0.0280381C10.4954 -0.135113 9.66275 0.43029 9.49679 1.29387C9.33364 2.15744 9.89904 2.99007 10.7626 3.15604Z"
            fill="#3B7684"/>
        <path
            d="M11.9891 7.96331C15.373 8.40776 17.9947 11.3107 17.9947 14.8157C17.9947 18.6272 14.8948 21.7271 11.0805 21.7271C7.26612 21.7271 4.16625 18.6272 4.16625 14.8157C4.16625 11.3473 6.73447 8.46683 10.0706 7.97738V5.69045C6.31815 6.10114 3.24078 8.78188 2.24219 12.3262C2.58537 12.4753 2.88917 12.7594 3.12827 13.1588C3.46301 13.7158 3.65429 14.4584 3.66554 15.2517C3.68523 16.5597 3.22953 17.6511 2.52911 18.1603C3.86807 21.5752 7.19579 23.9999 11.0805 23.9999C14.9652 23.9999 18.2282 21.6202 19.5925 18.2587C19.3196 18.0956 19.0721 17.848 18.8724 17.5133C18.5376 16.9563 18.3463 16.2137 18.3351 15.4204C18.3238 14.6272 18.4926 13.8705 18.8133 13.2854C19.0946 12.7735 19.4743 12.4275 19.9075 12.2812C18.8836 8.72844 15.7697 6.05332 11.9891 5.6792V7.96613V7.96331Z"
            fill={color}/>
        <path
            d="M2.32675 13.4795C2.24236 13.3389 2.14953 13.2207 2.05389 13.1279C1.89918 12.976 1.73603 12.8944 1.59257 12.9001C1.58132 12.9001 1.57288 12.9001 1.56163 12.9001C1.33659 12.9282 1.07499 13.1673 0.875267 13.5358C0.605224 14.0337 0.464576 14.6863 0.473015 15.3811C0.484267 16.0759 0.644605 16.7172 0.9259 17.187C1.14812 17.5527 1.42942 17.7749 1.66008 17.7665C1.84573 17.758 2.05952 17.6033 2.24799 17.3304C2.54616 16.9057 2.79089 16.194 2.77682 15.2854C2.76557 14.5906 2.60523 13.9493 2.32394 13.4795H2.32675Z"
            fill={color}/>
        <path
            d="M21.0748 13.4795C20.8526 13.1138 20.5713 12.8916 20.3406 12.9C20.3294 12.9 20.3209 12.9 20.3097 12.9C20.2365 12.9085 20.1578 12.9422 20.0818 12.9957C19.9215 13.1025 19.7583 13.291 19.6233 13.5386C19.3533 14.0308 19.2126 14.6834 19.2211 15.3782C19.2323 16.073 19.3927 16.7144 19.6739 17.1841C19.7358 17.2882 19.8033 17.3782 19.8737 17.457C20.0509 17.6567 20.2422 17.772 20.4081 17.7636C20.8835 17.7383 21.5474 16.7678 21.5277 15.2826C21.5164 14.5878 21.3561 13.9464 21.0748 13.4767V13.4795Z"
            fill={color}/>
        <path
            d="M7.68316 12.3741C7.02774 12.346 6.49609 13.294 6.49609 14.4923C6.49609 15.6906 7.02774 16.6864 7.68597 16.7145C8.3442 16.7427 8.87304 15.7947 8.87304 14.5964C8.87304 13.3981 8.34139 12.4023 7.68316 12.3741ZM8.09385 16.3882C7.80693 16.6555 7.39061 16.5739 7.16276 16.2082C6.93491 15.8425 6.97992 15.3277 7.26684 15.0605C7.55376 14.7933 7.97008 14.8749 8.19793 15.2405C8.42578 15.6062 8.38077 16.121 8.09385 16.3882Z"
            fill={color}/>
        <path
            d="M13.6179 12.2306C12.9624 12.2025 12.4308 13.1504 12.4336 14.3487C12.4336 15.5471 12.9681 16.54 13.6235 16.5682C14.2789 16.5963 14.8105 15.6483 14.8077 14.45C14.8077 13.2517 14.2733 12.2587 13.6179 12.2306ZM14.006 16.2981C13.7163 16.5597 13.3 16.4753 13.0778 16.104C12.8555 15.7355 12.909 15.2208 13.1987 14.9592C13.4885 14.6975 13.9048 14.7819 14.127 15.1532C14.3492 15.5246 14.2958 16.0365 14.006 16.2981Z"
            fill={color}/>
        <path
            d="M11.3514 7.90999V3.94373H10.7129V7.91561C10.8367 7.90998 10.9604 7.90436 11.0842 7.90436C11.1742 7.90436 11.2642 7.90717 11.3514 7.90999Z"
            fill={color}/>
    </svg>


);

export default IconRobotHover;
