import { Product, PlanData } from "./interfaces";

export const filterMoreProducts = (moreProducts: Product[], activePlans: PlanData[]): Product[] => {
    const filteredActivePlans = activePlans.filter(plan => plan.plan);
    const activeProductNames = new Set(filteredActivePlans.map(plan => plan.name));

    return moreProducts.filter(product =>
        !Array.from(activeProductNames).some(activeName => product.name.includes(activeName))
    );
};
export const mergeProductsWithPlans = (moreProducts: Product[], activePlans: PlanData[]): PlanData[] => {
    const filteredActivePlans = activePlans.filter(plan => plan.plan);
    const activeProductNames = new Set(filteredActivePlans.map(plan => plan.name));
    const newProducts = moreProducts.filter(product =>
        !Array.from(activeProductNames).some(activeName => product.name.includes(activeName))
    );

    const newPlanData: PlanData[] = newProducts.map(product => ({
        plan: false, 
        ...product,
    }));

    // Devolvemos la unión de los planes activos y los nuevos productos
    return [...filteredActivePlans, ...newPlanData];
};


export const linkProductActive = (title: string) => {
    const envKey = `VITE_${title.toUpperCase()}_URL`;
    const url = import.meta.env[envKey];
    
    if (url) {
        window.location.href = url;
    } else {
        console.error(`No URL found for ${envKey}`);
    }
}