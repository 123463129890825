import { Box, Grid, Heading, Textarea, Stack, Flex, Text } from "@chakra-ui/react";
import { Input, Button, PhotoUpload } from "@canaia/ui-kit";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { createOrganization } from "../../api/Calls/formOrganization/createOrganization";
import { updateOrganization } from "../../api/Calls/formOrganization/editOrganization";
import { authStore } from "../../store/authStore";
import { useGetTokenOrganization } from "../../hooks/useGetAccessTokenOrganization";
import { useLogto } from "@logto/react";
import useToastError from "../../hooks/useToastError";
import useToastSuccess from "../../hooks/useToasSuccess";

const OrganizationForm = () => {
    const { t } = useTranslation();
    const showToastError = useToastError();
    const showToastSuccess = useToastSuccess();
    const { getAccessToken } = useLogto();
    const { fetchUpdatedToken } = useGetTokenOrganization();
    const location = useLocation(); 
    const { organizationId } = authStore.getState();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        numberFiscal: "",
        direction: "",
        population: "",
        postalCode: "",
        province: "",
        country: "",
    });

    const handleInputChange = (field: string, value: string | File) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            if (organizationId && location.pathname === "/edit-organization") {
                const token = await fetchUpdatedToken() ?? "";
                await updateOrganization(token, formData, organizationId);
                showToastSuccess("¡Operación exitosa!");
                return;
            } 
          
            if (location.pathname === "/create-organization") {
                const token = await getAccessToken(import.meta.env.VITE_LOGTO_API_ID) ?? "";
                await createOrganization(token, formData);
                showToastSuccess("¡Operación exitosa!");
                return;
            }
      
            showToastError("Ruta no reconocida.");
        } catch {
            showToastError("Ocurrió un error. Inténtalo de nuevo.");
        } finally {
            setIsLoading(false);
        }
    };
      

    return (
        <Box p={8} borderRadius="lg" maxW="900px" mx="auto">
            <Heading as="h2" size="lg" mb={10} textAlign={"left"}>
        Organización
            </Heading>
            <Flex width="100%">
                <Stack spacing={4} mb={8} mr={8} flex="1" mt={2}>
                    <Text textAlign="left" fontSize="16px" fontWeight="700"> Image </Text>
                    <PhotoUpload
                        description={t("uploadYourProfilePhoto")}
                        iconColor="primary.500"
                        onUpload={(file: File) => console.log('Por ahora no lo mandamos al formulario.', file)}
                    />
                </Stack>
                <Stack spacing={4} mb={8} flex="4">
                    <Input
                        label="Nombre de empresa"
                        placeholder="Placeholder"
                        isRequired
                        info
                        infoText="Ingresa el nombre de tu empresa."
                        product="primary"
                        value={formData.name}
                        onChange={(value: string) =>
                            handleInputChange("name", value)
                        }
                    />
                    <Textarea
                        placeholder="Descripción de la empresa"
                        size="md"
                        bg="white"
                        borderRadius="md"
                        h="100px"
                        value={formData.description}
                        onChange={(e) =>
                            handleInputChange("description", e.target.value)
                        }
                    />
                </Stack>
            </Flex>

            <Stack spacing={1} mb={8}>
                <Input
                    label="Número de identificación fiscal"
                    placeholder="Placeholder"
                    info
                    infoText="Ingresa el número fiscal de la empresa."
                    product="primary"
                    value={formData.numberFiscal}
                    onChange={(value: string) => handleInputChange("numberFiscal", value)}
                />
            </Stack>
            <Heading as="h3" size="md" mb={4} mt="70px" textAlign={"left"} color="black.400">
        Dirección de facturación
            </Heading>
            <Stack spacing={4} mb={8}>
                <Input
                    label="Dirección"
                    placeholder="Placeholder"
                    info
                    infoText="Ingresa la dirección de facturación."
                    product="primary"
                    value={formData.direction}
                    onChange={(value: string) => handleInputChange("direction", value)}
                />
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <Input
                        label="Población"
                        placeholder="Placeholder"
                        info
                        infoText="Ingresa la población."
                        product="primary"
                        value={formData.population}
                        onChange={(value: string) => handleInputChange("population", value)}
                    />
                    <Input
                        label="Código postal"
                        placeholder="Placeholder"
                        info
                        infoText="Ingresa el código postal."
                        product="primary"
                        value={formData.postalCode}
                        onChange={(value: string) =>
                            handleInputChange("postalCode", value)
                        }
                    />
                </Grid>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <Input
                        label="Provincia"
                        placeholder="Placeholder"
                        info
                        infoText="Ingresa la provincia."
                        product="primary"
                        value={formData.province}
                        onChange={(value: string) => handleInputChange("province", value)}
                    />
                    <Input
                        label="País"
                        placeholder="Placeholder"
                        info
                        infoText="Selecciona el país."
                        product="primary"
                        value={formData.country}
                        onChange={(value: string) => handleInputChange("country", value)}
                    />
                </Grid>
            </Stack>

            <Flex justify="space-between">
                <Button
                    color="primary.500"
                    type="outline"
                    onClick={() => console.log("Volver")}
                    text="Back"
                />
                <Button color="primary.500" onClick={handleSave} text="Save" isLoading={isLoading}/>
            </Flex>
        </Box>
    );
};

export default OrganizationForm;
