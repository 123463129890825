import React from 'react';

interface assistSvgProps extends React.SVGAttributes<SVGElement> {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

const assistSvg: React.FC<assistSvgProps> = ({
    width = 164,
    height = 42,
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 164 42" fill="none">
        <path d="M63.0843 29.1849H49.3482V36.053C49.3482 36.694 49.1274 37.238 48.6857 37.6797C48.2439 38.1215 47.6999 38.3423 47.0589 38.3423C46.4179 38.3423 45.8738 38.1215 45.4321 37.6797C44.9904 37.238 44.7695 36.694 44.7695 36.053V17.7382C44.7695 16.1492 45.0658 14.6624 45.6637 13.2727C46.2616 11.8829 47.075 10.6709 48.1147 9.63126C49.1543 8.59163 50.3663 7.77824 51.7561 7.18032C53.1458 6.58779 54.6326 6.28613 56.2216 6.28613C57.8107 6.28613 59.2974 6.5824 60.6872 7.18032C62.077 7.77824 63.289 8.59163 64.3286 9.63126C65.3682 10.6709 66.1816 11.8829 66.7795 13.2727C67.3775 14.6624 67.6737 16.1492 67.6737 17.7382V36.053C67.6737 36.694 67.4529 37.238 67.0112 37.6797C66.5695 38.1215 66.0254 38.3423 65.3844 38.3423C64.7434 38.3423 64.1993 38.1215 63.7576 37.6797C63.3159 37.238 63.095 36.694 63.095 36.053V29.1849H63.0843ZM63.0843 17.7382C63.0843 16.7902 62.9011 15.9014 62.5348 15.061C62.1685 14.2207 61.6783 13.4989 61.0696 12.8848C60.461 12.2761 59.7337 11.7859 58.8934 11.4196C58.0531 11.0534 57.1589 10.8702 56.2162 10.8702C55.2736 10.8702 54.374 11.0534 53.5391 11.4196C52.6987 11.7859 51.9715 12.2761 51.3628 12.8848C50.7541 13.4935 50.2639 14.2207 49.8977 15.061C49.5314 15.9014 49.3482 16.7956 49.3482 17.7382V24.6063H63.0843V17.7382Z" fill="#333333"/>
        <path d="M75.5354 38.3426C74.8944 38.3426 74.3504 38.1218 73.9087 37.6801C73.4669 37.2384 73.2461 36.6943 73.2461 36.0533C73.2461 35.4123 73.4669 34.8682 73.9087 34.4265C74.3504 33.9848 74.8944 33.764 75.5354 33.764H84.6928C85.3338 33.764 85.8779 33.5431 86.3196 33.1014C86.7613 32.6597 86.9821 32.1156 86.9821 31.4746C86.9821 30.8336 86.7613 30.2895 86.3196 29.8478C85.8779 29.4061 85.3338 29.1853 84.6928 29.1853H80.1141C79.1661 29.1853 78.2719 29.0021 77.4369 28.6358C76.5966 28.2695 75.8748 27.7793 75.2607 27.1706C74.652 26.562 74.1618 25.8348 73.7955 24.9944C73.4292 24.1541 73.2461 23.2599 73.2461 22.3172C73.2461 21.3746 73.4292 20.4804 73.7955 19.6401C74.1618 18.7997 74.652 18.0779 75.2607 17.4638C75.8694 16.8551 76.5966 16.365 77.4369 15.9987C78.2773 15.6324 79.1715 15.4492 80.1141 15.4492H86.5728C87.1814 15.4492 87.7147 15.6701 88.1726 16.1118C88.6305 16.5535 88.8621 17.0975 88.8621 17.7386C88.8621 18.3796 88.6305 18.9236 88.1726 19.3653C87.7147 19.807 87.1814 20.0279 86.5728 20.0279H80.1141C79.4731 20.0279 78.929 20.2488 78.4873 20.6905C78.0456 21.1322 77.8248 21.6762 77.8248 22.3172C77.8248 22.9583 78.0456 23.5023 78.4873 23.944C78.929 24.3857 79.4731 24.6066 80.1141 24.6066H84.6928C85.6409 24.6066 86.5297 24.7897 87.37 25.156C88.2103 25.5223 88.9321 26.0125 89.5462 26.6212C90.1549 27.2299 90.6451 27.9571 91.0114 28.7974C91.3777 29.6378 91.5608 30.5319 91.5608 31.4746C91.5608 32.4173 91.3777 33.3115 91.0114 34.1518C90.6451 34.9921 90.1549 35.7139 89.5462 36.328C88.9375 36.9367 88.2103 37.4269 87.37 37.7932C86.5297 38.1595 85.6355 38.3426 84.6928 38.3426H75.5354Z" fill="#333333"/>
        <path d="M97.9729 38.3426C97.3319 38.3426 96.7879 38.1218 96.3462 37.6801C95.9044 37.2384 95.6836 36.6943 95.6836 36.0533C95.6836 35.4123 95.9044 34.8682 96.3462 34.4265C96.7879 33.9848 97.3319 33.764 97.9729 33.764H107.13C107.771 33.764 108.315 33.5431 108.757 33.1014C109.199 32.6597 109.42 32.1156 109.42 31.4746C109.42 30.8336 109.199 30.2895 108.757 29.8478C108.315 29.4061 107.771 29.1853 107.13 29.1853H102.552C101.604 29.1853 100.709 29.0021 99.8744 28.6358C99.0341 28.2695 98.3123 27.7793 97.6982 27.1706C97.0895 26.562 96.5993 25.8348 96.233 24.9944C95.8667 24.1541 95.6836 23.2599 95.6836 22.3172C95.6836 21.3746 95.8667 20.4804 96.233 19.6401C96.5993 18.7997 97.0895 18.0779 97.6982 17.4638C98.3069 16.8551 99.0341 16.365 99.8744 15.9987C100.715 15.6324 101.604 15.4492 102.552 15.4492H109.005C109.614 15.4492 110.147 15.6701 110.605 16.1118C111.063 16.5535 111.294 17.0975 111.294 17.7386C111.294 18.3796 111.068 18.9236 110.605 19.3653C110.147 19.807 109.614 20.0279 109.005 20.0279H102.552C101.911 20.0279 101.367 20.2488 100.925 20.6905C100.483 21.1322 100.262 21.6762 100.262 22.3172C100.262 22.9583 100.483 23.5023 100.925 23.944C101.367 24.3857 101.911 24.6066 102.552 24.6066H107.13C108.078 24.6066 108.967 24.7897 109.807 25.156C110.648 25.5223 111.37 26.0125 111.984 26.6212C112.592 27.2299 113.083 27.9571 113.449 28.7974C113.815 29.6378 113.998 30.5319 113.998 31.4746C113.998 32.4173 113.815 33.3115 113.449 34.1518C113.083 34.9921 112.592 35.7139 111.984 36.328C111.375 36.9367 110.648 37.4269 109.807 37.7932C108.967 38.1595 108.073 38.3426 107.13 38.3426H97.9729Z" fill="#333333"/>
        <path d="M123.064 8.81247C123.064 9.51274 122.821 10.1107 122.331 10.6009C121.841 11.091 121.249 11.3334 120.548 11.3334C119.848 11.3334 119.25 11.091 118.76 10.6009C118.27 10.1107 118.027 9.51813 118.027 8.81247C118.027 8.10682 118.27 7.51428 118.76 7.02409C119.25 6.5339 119.843 6.2915 120.548 6.2915C121.254 6.2915 121.847 6.5339 122.331 7.02409C122.821 7.51428 123.064 8.10682 123.064 8.81247ZM118.259 36.0529C118.259 36.694 118.48 37.238 118.922 37.6797C119.363 38.1214 119.907 38.3423 120.548 38.3423C121.189 38.3423 121.733 38.1214 122.175 37.6797C122.617 37.238 122.838 36.694 122.838 36.0529V17.7382C122.838 17.0972 122.617 16.5531 122.175 16.1114C121.733 15.6697 121.189 15.4489 120.548 15.4489C119.907 15.4489 119.363 15.6697 118.922 16.1114C118.48 16.5531 118.259 17.0972 118.259 17.7382V36.0529Z" fill="#333333"/>
        <path d="M131.399 38.3426C130.758 38.3426 130.214 38.1218 129.772 37.6801C129.33 37.2384 129.109 36.6943 129.109 36.0533C129.109 35.4123 129.33 34.8682 129.772 34.4265C130.214 33.9848 130.758 33.764 131.399 33.764H140.556C141.197 33.764 141.741 33.5431 142.183 33.1014C142.625 32.6597 142.845 32.1156 142.845 31.4746C142.845 30.8336 142.625 30.2895 142.183 29.8478C141.741 29.4061 141.197 29.1853 140.556 29.1853H135.977C135.029 29.1853 134.135 29.0021 133.3 28.6358C132.46 28.2695 131.738 27.7793 131.124 27.1706C130.515 26.562 130.025 25.8348 129.659 24.9944C129.293 24.1541 129.109 23.2599 129.109 22.3172C129.109 21.3746 129.293 20.4804 129.659 19.6401C130.025 18.7997 130.515 18.0779 131.124 17.4638C131.733 16.8551 132.46 16.365 133.3 15.9987C134.141 15.6324 135.035 15.4492 135.977 15.4492H142.436C143.045 15.4492 143.578 15.6701 144.036 16.1118C144.494 16.5535 144.725 17.0975 144.725 17.7386C144.725 18.3796 144.499 18.9236 144.036 19.3653C143.578 19.807 143.045 20.0279 142.436 20.0279H135.977C135.336 20.0279 134.792 20.2488 134.351 20.6905C133.909 21.1322 133.688 21.6762 133.688 22.3172C133.688 22.9583 133.909 23.5023 134.351 23.944C134.792 24.3857 135.336 24.6066 135.977 24.6066H140.556C141.504 24.6066 142.393 24.7897 143.233 25.156C144.074 25.5223 144.795 26.0125 145.409 26.6212C146.018 27.2299 146.508 27.9571 146.875 28.7974C147.241 29.6378 147.424 30.5319 147.424 31.4746C147.424 32.4173 147.241 33.3115 146.875 34.1518C146.508 34.9921 146.018 35.7139 145.409 36.328C144.801 36.9421 144.074 37.4269 143.233 37.7932C142.393 38.1595 141.499 38.3426 140.556 38.3426H131.399Z" fill="#333333"/>
        <path d="M151.453 8.58085C151.453 7.93983 151.674 7.39577 152.116 6.95407C152.557 6.51236 153.101 6.2915 153.742 6.2915C154.383 6.2915 154.928 6.51236 155.369 6.95407C155.811 7.39577 156.032 7.93983 156.032 8.58085V15.4489H159.695C160.336 15.4489 160.88 15.6697 161.322 16.1114C161.763 16.5531 161.984 17.0972 161.984 17.7382C161.984 18.3792 161.763 18.9233 161.322 19.365C160.88 19.8067 160.336 20.0276 159.695 20.0276H156.032V31.4743C156.032 32.083 156.253 32.6162 156.694 33.0741C157.136 33.532 157.68 33.7636 158.321 33.7636C158.962 33.7636 159.463 33.5374 159.926 33.0741C160.39 32.6109 160.934 32.39 161.575 32.39C162.216 32.39 162.749 32.6162 163.175 33.0741C163.632 33.4997 163.864 34.0383 163.864 34.6793C163.864 35.3204 163.638 35.8698 163.175 36.3277C162.566 36.9364 161.839 37.4266 160.998 37.7929C160.158 38.1591 159.264 38.3423 158.321 38.3423C157.378 38.3423 156.484 38.1591 155.644 37.7929C154.804 37.4266 154.082 36.9364 153.468 36.3277C152.859 35.719 152.369 34.9918 152.003 34.1514C151.636 33.3111 151.453 32.4169 151.453 31.4743V8.58085Z" fill="#333333"/>
        <path d="M16.6065 22.7427C18.0999 22.7427 19.3106 21.532 19.3106 20.0386C19.3106 18.5451 18.0999 17.3345 16.6065 17.3345C15.113 17.3345 13.9023 18.5451 13.9023 20.0386C13.9023 21.532 15.113 22.7427 16.6065 22.7427Z" fill="#5AA9E6"/>
        <path d="M24.0127 22.7427C25.5062 22.7427 26.7168 21.532 26.7168 20.0386C26.7168 18.5451 25.5062 17.3345 24.0127 17.3345C22.5193 17.3345 21.3086 18.5451 21.3086 20.0386C21.3086 21.532 22.5193 22.7427 24.0127 22.7427Z" fill="#5AA9E6"/>
        <path d="M31.4151 22.7427C32.9085 22.7427 34.1192 21.532 34.1192 20.0386C34.1192 18.5451 32.9085 17.3345 31.4151 17.3345C29.9216 17.3345 28.7109 18.5451 28.7109 20.0386C28.7109 21.532 29.9216 22.7427 31.4151 22.7427Z" fill="#5AA9E6"/>
        <path d="M33.6022 30.0254C32.4494 29.1635 30.8065 29.2443 29.8153 30.2893C28.878 31.2751 27.8007 32.11 26.5833 32.8049C24.6333 33.9146 22.4948 34.4694 20.1624 34.4694C18.2663 34.4694 16.494 34.087 14.8349 33.322C13.1758 32.5571 11.7268 31.5175 10.4933 30.2085C9.25433 28.8996 8.27395 27.3698 7.54136 25.6191C6.81416 23.8684 6.44787 21.9938 6.44787 19.99C6.44787 17.9861 6.81416 16.1116 7.54136 14.3609C8.26857 12.6156 9.25433 11.0858 10.4933 9.77145C11.7322 8.46248 13.1812 7.42285 14.8349 6.65794C16.494 5.89304 18.2663 5.51058 20.1624 5.51058C22.4948 5.51058 24.6333 6.06541 26.5833 7.17507C27.8007 7.86995 28.878 8.70489 29.8153 9.69065C30.8119 10.7303 32.4494 10.8165 33.6022 9.9546C35.0458 8.87726 35.1697 6.76029 33.8715 5.51058C32.514 4.20162 30.9788 3.09196 29.2551 2.17622C26.5079 0.727203 23.4752 0 20.1624 0C17.4313 0 14.8619 0.527895 12.4594 1.58369C10.0569 2.63948 7.96152 4.06695 6.17853 5.87149C4.39554 7.67603 2.98422 9.78761 1.94459 12.2062C0.904963 14.6302 0.387842 17.2212 0.387842 19.99C0.387842 22.7588 0.904963 25.3551 1.94459 27.7738C2.40246 28.8403 2.94113 29.8369 3.54444 30.7849L0 42L12.1362 38.2455C12.2439 38.294 12.3463 38.3532 12.4594 38.4017C14.8619 39.4575 17.4313 39.9854 20.1624 39.9854C23.4752 39.9854 26.5079 39.2582 29.2605 37.7984C30.9788 36.888 32.5194 35.7784 33.8769 34.464C35.1751 33.2143 35.0512 31.0973 33.6075 30.02L33.6022 30.0254Z" fill="#5AA9E6"/>
    </svg>
);

export default assistSvg;
