import {useLogto} from '@logto/react';
import './App.css'
import AppRoutes from './router/AppRouter.tsx'
import {useCheckTokenExpiration} from './hooks/useCheckTokenExpiration.tsx';
import {useGetAccessToken} from './hooks/useGetAccessToken.tsx';
import {useEffect, useState} from 'react';
import {authStore} from './store/authStore.tsx';
import i18n from './translator/i18n.ts';
import {fetchOrganizations} from './api/Calls/getOrganizations.tsx';
import useToastError from './hooks/useToastError/index.tsx';
import {Flex, Spinner} from '@chakra-ui/react';
import {getUser} from "./api/Calls/form.tsx";

interface CustomData {
    language?: string;
}

function App() {
    const {isAuthenticated, fetchUserInfo, signOut, getAccessToken} = useLogto();
    const {setUserName, setInfoUser, setLanguage, setFirstToken, setOrganizationId} = authStore.getState();
    const {isTokenExpired} = useCheckTokenExpiration();
    const {language} = authStore((state) => state);
    const [isLoadingOrganization, setIsLoadingOrganization] = useState(false);
    const showToastError = useToastError();
    const {resetAuthState} = authStore.getState();
    useGetAccessToken();

    useEffect(() => {
        if (i18n.language !== language) {
            i18n.changeLanguage(language);
        }
    }, [language]);

    useEffect(() => {
        if (!isAuthenticated) {
            resetAuthState();
        }
        const checkTokenAndSignOut = async () => {
            setIsLoadingOrganization(true);
            if (isAuthenticated) {
                const token = await getAccessToken(import.meta.env.VITE_LOGTO_API_ID);
                if (token) {
                    setFirstToken(token);
                    await fetchOrganizations(token, signOut, showToastError).then((response) => {
                        if (response.length > 0) {
                            setOrganizationId(response[0].id);
                        } else {
                            setOrganizationId("");
                        }
                    })
                    const additionalUserData = await getUser(token, signOut);
                    const userInfo = await fetchUserInfo();
                    setInfoUser({
                        email: userInfo?.email || "",
                        name: additionalUserData.name || '',
                        last_name: additionalUserData.last_name || '',
                        phone: additionalUserData.phone || '',
                        address: additionalUserData.address || '',
                        city: additionalUserData.city || '',
                        country: additionalUserData.country || '',
                        postal_code: additionalUserData.postal_code || '',
                    })
                }
                const userInfo = await fetchUserInfo();
                const customData = userInfo?.custom_data as CustomData;
                const preferredLanguage = customData?.language || navigator.language;
                const normalizedLanguage = preferredLanguage.includes("-ES") ? "es" : preferredLanguage.split("-")[0] === "es" ? "es" : "en";
                if (i18n.language !== normalizedLanguage) {
                    i18n.changeLanguage(normalizedLanguage)
                    setLanguage(normalizedLanguage);
                }
                setUserName(userInfo?.name || "");
            }
        };
        checkTokenAndSignOut().finally(() => {
            setIsLoadingOrganization(false)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isTokenExpired]);

    if (isLoadingOrganization) {
        // Muestra un spinner de carga mientras se obtiene la organización
        return (
            <Flex justify="center" align="center" height="100vh">
                <Spinner size="xl" color="teal.500"/>
            </Flex>
        );
    }

    return (
        <>
            <AppRoutes/>
        </>
    )
}

export default App;